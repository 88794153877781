var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-full-dialog",
      attrs: {
        visible: _vm.visible,
        fullscreen: "",
        "lock-scroll": "",
        "show-close": false,
        modal: false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "SNOWS-full-dialog-header" },
        [
          _c("div", { staticClass: "header-title" }, [
            _c("img", {
              staticClass: "header-logo",
              attrs: { src: require("@/assets/images/default_avatar.png") },
            }),
            _c("p", { staticClass: "header-txt" }, [_vm._v(" · 流程设计")]),
          ]),
          _c(
            "el-steps",
            {
              staticClass: "steps steps2",
              attrs: {
                active: _vm.activeStep,
                "finish-status": "success",
                simple: "",
              },
            },
            [
              _c("el-step", {
                attrs: { title: "基础信息" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.stepChick(0)
                  },
                },
              }),
              _c("el-step", {
                attrs: { title: "流程设计" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.stepChick(1)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.activeStep <= 0 || _vm.btnLoading },
                  on: { click: _vm.prev },
                },
                [_vm._v(_vm._s("上一步") + "\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      _vm.activeStep >= 1 || _vm.loading || _vm.btnLoading,
                  },
                  on: { click: _vm.next },
                },
                [_vm._v("\n        " + _vm._s("下一步") + "\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.loading,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.dataFormSubmit()
                    },
                  },
                },
                [_vm._v("保 存\n      ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(_vm._s("取消"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          !_vm.activeStep
            ? _c(
                "el-row",
                {
                  staticClass: "basic-box",
                  attrs: { type: "flex", justify: "center", align: "middle" },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "basicForm",
                      attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 10 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          attrs: {
                            model: _vm.dataForm,
                            rules: _vm.dataRule,
                            "label-width": "80px",
                            "label-position": "right",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "流程名称", prop: "fullName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "流程名称",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.dataForm.fullName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "fullName", $$v)
                                  },
                                  expression: "dataForm.fullName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "流程编码", prop: "enCode" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "流程编码",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.dataForm.enCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "enCode", $$v)
                                  },
                                  expression: "dataForm.enCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "流程分类", prop: "category" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "选择分类" },
                                  model: {
                                    value: _vm.dataForm.category,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "category", $$v)
                                    },
                                    expression: "dataForm.category",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    { attrs: { value: _vm.treeDataValue } },
                                    [
                                      _c("el-tree", {
                                        ref: "tree",
                                        attrs: {
                                          data: _vm.treeData,
                                          "default-expand-all": "",
                                          "node-key": "id",
                                          props: _vm.defaultProps,
                                        },
                                        on: {
                                          "node-click": _vm.handleNodeClick,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "流程类型", prop: "type" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "50", disabled: "" },
                                model: {
                                  value: _vm.type,
                                  callback: function ($$v) {
                                    _vm.type = $$v
                                  },
                                  expression: "type",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.dataForm.type == 0
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "流程图标", prop: "icon" } },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { type: "flex" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            flex: "1",
                                            "margin-right": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                placeholder: "请选择流程图标",
                                                readonly: "",
                                                "suffix-icon":
                                                  _vm.dataForm.icon,
                                              },
                                              model: {
                                                value: _vm.dataForm.icon,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm,
                                                    "icon",
                                                    $$v
                                                  )
                                                },
                                                expression: "dataForm.icon",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { slot: "append" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openIconBox()
                                                    },
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v("选择")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-color-picker", {
                                        attrs: {
                                          title: "图标背景色",
                                          predefine: [
                                            "#008cff",
                                            "#35b8e0",
                                            "#00cc88",
                                            "#ff9d00",
                                            "#ff4d4d",
                                            "#5b69bc",
                                            "#ff8acc",
                                            "#3b3e47",
                                            "#282828",
                                          ],
                                        },
                                        model: {
                                          value: _vm.dataForm.iconBackground,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataForm,
                                              "iconBackground",
                                              $$v
                                            )
                                          },
                                          expression: "dataForm.iconBackground",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "流程排序", prop: "sortCode" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  max: 999999,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.dataForm.sortCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "sortCode", $$v)
                                  },
                                  expression: "dataForm.sortCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "流程说明", prop: "description" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "流程说明",
                                  type: "textarea",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.dataForm.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "description", $$v)
                                  },
                                  expression: "dataForm.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeStep == 1
            ? _c("Process", {
                ref: "processDesign",
                attrs: {
                  conf: _vm.flowTemplateJson,
                  flowType: _vm.dataForm.type,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("icon-box", {
        ref: "iconBox",
        attrs: { visible: _vm.iconBoxVisible, current: _vm.dataForm.icon },
        on: {
          "update:visible": function ($event) {
            _vm.iconBoxVisible = $event
          },
          choiceIcon: _vm.choiceIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }