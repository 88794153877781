var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center",
      attrs: {
        title: "查看模板",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        "append-to-body": "",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { model: _vm.dataForm, "label-width": "100px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "fullName" } },
                    [_c("p", [_vm._v(_vm._s(_vm.dataForm.fullName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "编码", prop: "enCode" } },
                    [_c("p", [_vm._v(_vm._s(_vm.dataForm.enCode))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "模板类型" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.dataForm.templateType == "0"
                            ? "自定义模板"
                            : "系统模板"
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "消息来源", prop: "messageSource" } },
                    [_c("p", [_vm._v(_vm._s(_vm.dataForm.messageSourceVal))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "消息类型", prop: "messageType" } },
                    [_c("p", [_vm._v(_vm._s(_vm.dataForm.messageTypeVal))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "enabledMark" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          disabled: "",
                        },
                        model: {
                          value: _vm.dataForm.enabledMark,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "enabledMark", $$v)
                          },
                          expression: "dataForm.enabledMark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sortCode" } },
                    [_c("p", [_vm._v(_vm._s(_vm.dataForm.sortCode))])]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "说明", prop: "description" } },
                    [_c("p", [_vm._v(_vm._s(_vm.dataForm.description))])]
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "parameter-box" }, [
                  _c("div", { staticClass: "left-pane" }, [
                    _c("div", { staticClass: "left-pane-list" }, [
                      _c(
                        "div",
                        { staticClass: "list" },
                        [
                          _c("div", { staticClass: "header" }, [
                            _c("span", [_vm._v("参数名称")]),
                            _c("span", [_vm._v("参数说明")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              placeholder: "输入关键字",
                              "suffix-icon": "el-icon-search",
                              clearable: "",
                            },
                            on: { input: _vm.searchParameter },
                            model: {
                              value: _vm.keyword,
                              callback: function ($$v) {
                                _vm.keyword = $$v
                              },
                              expression: "keyword",
                            },
                          }),
                          _c(
                            "el-table",
                            {
                              ref: "dragTable",
                              attrs: {
                                data: _vm.parameterList,
                                showHeader: false,
                                "row-key": "id",
                                size: "mini",
                                height: "100%",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "field", label: "参数名称" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(scope.row.field) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "fieldName", label: "参数说明" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              cursor: "pointer",
                                              "line-height": "36px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(scope.row.fieldName) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm.dataForm.messageType != 3 && _vm.dataForm.messageType != 7
                    ? _c(
                        "div",
                        { staticClass: "right-pane" },
                        [
                          _c(
                            "snows-form-tip-item",
                            {
                              attrs: {
                                label: "消息标题",
                                prop: "title",
                                tipLabel: "参数格式：{参数名}",
                              },
                            },
                            [_c("p", [_vm._v(_vm._s(_vm.dataForm.title))])]
                          ),
                          _vm.dataForm.messageType == 2
                            ? _c(
                                "snows-form-tip-item",
                                {
                                  attrs: {
                                    label: "消息内容",
                                    prop: "content",
                                    tipLabel: "参数格式：{参数名}",
                                  },
                                },
                                [
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.dataForm.content),
                                    },
                                  }),
                                ]
                              )
                            : _vm.dataForm.messageType != 1 ||
                              _vm.dataForm.messageSource != 1
                            ? _c(
                                "snows-form-tip-item",
                                {
                                  attrs: {
                                    label: "消息内容",
                                    prop: "content",
                                    tipLabel: "参数格式：{参数名}",
                                  },
                                },
                                [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.dataForm.content)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "right-pane" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "snows-form-tip-item",
                                    {
                                      attrs: {
                                        label: "模版编号",
                                        prop: "templateCode",
                                        tipLabel:
                                          _vm.dataForm.messageType == 3
                                            ? "阿里云：请在【阿里云管理后台-模板管理】⻚⾯查看模板CODE<br/>腾讯云：请在【腾讯云管理后台-正⽂模板管理】⻚⾯查看模板ID"
                                            : "在【微信公众号管理后台-广告与服务-模板消息】⻚⾯查看模板ID",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.dataForm.templateCode)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 12, offset: 12 } }),
                              _vm.dataForm.messageType == 7
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "snows-form-tip-item",
                                        {
                                          attrs: {
                                            label: "跳转方式",
                                            prop: "wxSkip",
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataForm.wxSkip == 1
                                                  ? "小程序"
                                                  : "页面"
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.dataForm.messageType == 7 &&
                              _vm.dataForm.wxSkip == 1
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "snows-form-tip-item",
                                        {
                                          attrs: {
                                            label: "关联小程序ID",
                                            prop: "xcxAppId",
                                            "label-width": "126px",
                                            tipLabel:
                                              "在【微信公众号管理后台-广告与服务-小程序管理】⻚⾯查看小程序ID",
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(_vm.dataForm.xcxAppId)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "msg-pane" }, [
                            _c(
                              "div",
                              { staticClass: "list" },
                              [
                                _c(
                                  "el-table",
                                  {
                                    ref: "dragTable",
                                    attrs: {
                                      data: _vm.smsList,
                                      "row-key": "id",
                                      size: "mini",
                                      height: "100%",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "序号",
                                        type: "index",
                                        width: "50",
                                      },
                                    }),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: { prop: "name" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(scope.row.smsField)
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      },
                                      [
                                        _c("template", { slot: "header" }, [
                                          _vm.dataForm.messageType == 3
                                            ? _c(
                                                "p",
                                                [
                                                  _vm._v(
                                                    "\n                        短信变量\n                        "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "内容在第三方平台维护，绑定第三方平台短信变量，如：腾讯云：{1}，阿里云格式：${name}",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "p",
                                                [
                                                  _vm._v(
                                                    "\n                        变量\n                        "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "内容在微信公众号管理后台维护，绑定模板变量，如：{first.DATA}。",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _c("el-table-column", {
                                      attrs: { label: "参数" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "line-height": "36px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.field)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                    _vm.dataForm.messageType == 7
                                      ? _c("el-table-column", {
                                          attrs: { label: "标题" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-checkbox", {
                                                      attrs: {
                                                        disabled: "",
                                                        "true-label": 1,
                                                        "false-label": 0,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeKey(
                                                            $event,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.isTitle,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "isTitle",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.isTitle",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4139714806
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }