<template>
  <div class="popupSelect-container">
    <div class="el-select" @click="openDialog">
      <el-input :placeholder="placeholder" v-model="title" readonly :validate-event="false"
                @mouseenter.native="inputHovering = true" @mouseleave.native="inputHovering = false">
        <template slot="suffix">
          <i v-show="!showClose"
             :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-up']"></i>
          <i v-if="showClose" class="el-select__caret el-input__icon el-icon-circle-close"
             @click.stop="clear"></i>
        </template>
      </el-input>
    </div>
    <el-dialog :title="label+$t('cip.plat.sys.visual3.field.select')" :close-on-click-modal="false" :visible.sync="visible"
               class="SNOWS-dialog SNOWS-dialog_center SNOWS-dialog-tree-select" lock-scroll append-to-body
               width='800px'>
      <div class="SNOWS-common-layout">
        <div class="SNOWS-common-layout-center">
          <el-row class="SNOWS-common-search-box" :gutter="16">
            <el-form @submit.native.prevent>
              <el-col :span="10">
                <el-form-item label='功能名称'>
                  <el-input v-model="listQuery.keyword" placeholder='功能名称' clearable
                            @keyup.enter.native="search()" class="search-input" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item>
                  <el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
                  <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
                </el-form-item>
              </el-col>
            </el-form>
            <div class="SNOWS-common-search-box-right">
              <el-tooltip effect="dark" content="刷新" placement="top">
                <el-link icon="icon-ym icon-ym-Refresh SNOWS-common-head-icon" :underline="false"
                         @click="initData()" />
              </el-tooltip>
            </div>
          </el-row>
          <div class="SNOWS-common-layout-main SNOWS-flex-main">
            <SNOWSTable v-loading="listLoading" :data="list" :border="false" highlight-current-row
                        @row-click="rowClick" :hasNO="false">
              <el-table-column width="50">
                <template slot-scope="scope">
                  <el-radio :label="scope.row.id" v-model="checked">&nbsp;</el-radio>
                </template>
              </el-table-column>
              <el-table-column type="index" :label=$t('cip.plat.sys.visual3.field.orderNumber')  />
              <el-table-column prop="fullName" :label="label+$t('cip.plat.sys.visual3.field.nameInterface') " show-overflow-tooltip />
              <el-table-column prop="enCode" :label="label+$t('cip.plat.sys.visual3.field.code') " />
              <el-table-column prop="formType" :label="label+$t('cip.plat.sys.visual3.field.type') " width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.formType == 2 ? $t('cip.plat.sys.visual3.field.custom')+label : $t('cip.plat.sys.visual3.field.system')+label}}</span>
                </template>
              </el-table-column>
            </SNOWSTable>
            <pagination :total="total" :page.sync="listQuery.currentPage"
                        :limit.sync="listQuery.pageSize" @pagination="initData" />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false" size="small">{{$t('cip.cmn.btn.celBtn')}}</el-button>
        <el-button type="primary" @click="select()" size="small">{{$t('cip.cmn.btn.defBtn')}}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getFormSelect } from '@/api/workFlow/FormDesign.js'
import SNOWSTable from "@/components/SNOWS-table";
export default {
  name: 'FlowFormDialog',
  components: {SNOWSTable},
  props: {
    value: {
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    },
    formType: {
      default: null
    },
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      list: [],
      innerValue: '',
      listQuery: {
        keyword: '',
        currentPage: 1,
        pageSize: 20,
      },
      total: 0,
      checked: '',
      checkedRow: {},
      listLoading: false,
      inputHovering: false,
      visible: false
    }
  },
  computed: {
    showClose() {
      let hasValue = this.value !== undefined && this.value !== null && this.value !== '';
      let criteria = this.clearable &&
        !this.disabled &&
        this.inputHovering &&
        hasValue;
      return criteria;
    },
    label() {
      return this.type == 1 ? "功能" : "表单"
    }
  },
  methods: {
    initData() {
      this.listLoading = true
      let q = {
        ...this.listQuery,
        flowType: this.type,
        formType: this.formType,
      }
      getFormSelect(q).then(res => {
        this.list = res.data.data.records
        this.total = res.data.data.total
        this.listLoading = false
      }).catch(() => { this.listLoading = false })
    },
    openDialog() {
      if (this.disabled) return
      this.checked = this.value
      this.checkedRow = {
        id: this.value,
        fullName: this.title
      }
      this.visible = true
      this.reset()
    },
    reset() {
      this.listQuery.keyword = ''
      this.search()
    },
    search() {
      this.listQuery.currentPage = 1
      this.listQuery.pageSize = 20
      this.initData()
    },
    clear() {
      this.checked = ''
      this.checkedRow = {}
      this.$emit('input', this.checked)
      this.$emit('change', this.checked, this.checkedRow)
    },
    select() {
      if (!this.checked) return
      this.$emit('input', this.checked)
      this.$emit('change', this.checked, this.checkedRow)
      this.visible = false
    },
    rowClick(row) {
      this.checked = row.id
      this.checkedRow = row
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  max-height: 70vh;
  padding: 0 0 10px !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .SNOWS-common-search-box {
    margin-bottom: 0;
    .SNOWS-common-search-box-right {
      padding: 10px 10px 0 0;
    }
  }
}
</style>
