var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "el-zoom-in-center" } }, [
    _c("div", { staticClass: "SNOWS-preview-main" }, [
      _c(
        "div",
        { staticClass: "SNOWS-common-page-header" },
        [
          _c("el-page-header", {
            attrs: {
              content: !_vm.flowVisible ? _vm.title + "的版本管理" : "流程图",
            },
            on: { back: _vm.goBack },
          }),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.flowVisible
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c(
                "el-row",
                {
                  staticClass: "SNOWS-common-search-box",
                  attrs: { gutter: 16 },
                },
                [
                  _c(
                    "el-form",
                    {
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建时间" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "picker-options": _vm.pickerOptions,
                                  "value-format": "timestamp",
                                  clearable: "",
                                  editable: false,
                                },
                                model: {
                                  value: _vm.pickerVal,
                                  callback: function ($$v) {
                                    _vm.pickerVal = $$v
                                  },
                                  expression: "pickerVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.search()
                                    },
                                  },
                                },
                                [_vm._v("\n                  查询")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { icon: "el-icon-refresh-right" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset()
                                    },
                                  },
                                },
                                [_vm._v("重置\n                ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "SNOWS-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: { data: _vm.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "version",
                      label: "版本号",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-tag", [
                                _vm._v("V:" + _vm._s(scope.row.version)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2886249985
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "enabledMark",
                      label: "主版本",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.enabledMark == 1 ? "是" : "否"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3394595307
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "creatorUser", label: "创建人" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "creatorTime",
                      label: "创建时间",
                      formatter: _vm.snows.tableDateFormat,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "lastModifyTime",
                      label: "最后修改时间",
                      formatter: _vm.snows.tableDateFormat,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", width: "200" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    disabled: scope.row.enabledMark == 1,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.updateRelease(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("设为主版本\n              ")]
                              ),
                              scope.row.enabledMark == 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        size: "mini",
                                        disabled: scope.row.enabledMark == 1,
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.del(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                删除\n              "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "text", size: "mini" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.del(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                删除\n              "
                                      ),
                                    ]
                                  ),
                              _c(
                                "el-dropdown",
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                        },
                                        [
                                          _vm._v(_vm._s(_vm.$t("更多"))),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.flowInfo(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 流程图")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3431947500
                    ),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.currentPage,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "currentPage", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.initData,
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "main" },
            [_c("Process", { attrs: { conf: _vm.flowTemplateJson } })],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }