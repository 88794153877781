var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.properties
    ? _c(
        "el-drawer",
        {
          staticClass: "drawer SNOWS-common-drawer",
          attrs: {
            size: _vm.value && _vm.isConditionNode() ? "650px" : "600px",
            visible: _vm.visible,
            "append-to-body": "",
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.cancel,
          },
        },
        [
          _vm.value &&
          (_vm.value.type == "condition" ||
            _vm.value.type == "approver" ||
            _vm.value.type == "subFlow" ||
            _vm.value.type == "start")
            ? _c(
                "header",
                {
                  staticClass: "header",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("el-input", {
                    staticStyle: { "z-index": "9", "max-width": "200px" },
                    attrs: { size: "mini", placeholder: "请输入" },
                    model: {
                      value: _vm.properties.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.properties, "title", $$v)
                      },
                      expression: "properties.title",
                    },
                  }),
                ],
                1
              )
            : _c(
                "header",
                {
                  staticClass: "header",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v(_vm._s(_vm.properties.title))]
              ),
          _vm.value && _vm.isConditionNode()
            ? _c(
                "section",
                { staticClass: "condition-pane pd-10" },
                [
                  _c(
                    "el-row",
                    { staticClass: "condition-list condition-list-header" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "label", attrs: { span: 8 } },
                        [_vm._v("条件字段")]
                      ),
                      _c("el-col", { attrs: { span: 4 } }, [_vm._v("比较")]),
                      _c("el-col", { attrs: { span: 8 } }, [_vm._v("数据值")]),
                      _c("el-col", { attrs: { span: 3 } }, [_vm._v("逻辑")]),
                      _c("el-col", { attrs: { span: 1 } }),
                    ],
                    1
                  ),
                  _vm._l(_vm.pconditions, function (item, index) {
                    return [
                      _c(
                        "el-row",
                        { key: index, staticClass: "condition-list" },
                        [
                          _c(
                            "el-col",
                            { staticClass: "label", attrs: { span: 8 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass:
                                    "condition-select condition-type-select",
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.fieldTypeChange(item)
                                    },
                                  },
                                  model: {
                                    value: item.fieldType,
                                    callback: function ($$v) {
                                      _vm.$set(item, "fieldType", $$v)
                                    },
                                    expression: "item.fieldType",
                                  },
                                },
                                _vm._l(
                                  _vm.conditionTypeOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              item.fieldType === 3
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "edit-script-btn",
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editFormula(item)
                                        },
                                      },
                                    },
                                    [_vm._v("公式编辑\n          ")]
                                  )
                                : _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.fieldNameChange(
                                            $event,
                                            item,
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.field,
                                        callback: function ($$v) {
                                          _vm.$set(item, "field", $$v)
                                        },
                                        expression: "item.field",
                                      },
                                    },
                                    _vm._l(_vm.usedFormItems, function (item) {
                                      return _c("el-option", {
                                        key: item.__vModel__,
                                        attrs: {
                                          label: item.__config__.label,
                                          value: item.__vModel__,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "condition-select",
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.symbolChange($event, item)
                                    },
                                  },
                                  model: {
                                    value: item.symbol,
                                    callback: function ($$v) {
                                      _vm.$set(item, "symbol", $$v)
                                    },
                                    expression: "item.symbol",
                                  },
                                },
                                _vm._l(_vm.symbolOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticClass: "fieldValue", attrs: { span: 8 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass:
                                    "condition-select condition-type-select",
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.fieldValueTypeChange(item)
                                    },
                                  },
                                  model: {
                                    value: item.fieldValueType,
                                    callback: function ($$v) {
                                      _vm.$set(item, "fieldValueType", $$v)
                                    },
                                    expression: "item.fieldValueType",
                                  },
                                },
                                _vm._l(
                                  _vm.conditionTypeOptions1,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              item.fieldValueType === 2
                                ? _c(
                                    "div",
                                    [
                                      item.jnpfKey === "numInput"
                                        ? [
                                            _c("el-input-number", {
                                              attrs: {
                                                placeholder: "请输入",
                                                precision: item.precision,
                                                "controls-position": "right",
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : item.jnpfKey === "calculate"
                                        ? [
                                            _c("el-input-number", {
                                              attrs: {
                                                placeholder: "请输入",
                                                precision: 2,
                                                "controls-position": "right",
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : ["rate", "slider"].includes(
                                            item.jnpfKey
                                          )
                                        ? [
                                            _c("el-input-number", {
                                              attrs: {
                                                placeholder: "请输入",
                                                "controls-position": "right",
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : item.jnpfKey === "switch"
                                        ? [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": 1,
                                                "inactive-value": 0,
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : item.jnpfKey === "time"
                                        ? [
                                            _c("el-time-picker", {
                                              attrs: {
                                                "picker-options":
                                                  item["picker-options"],
                                                placeholder: "请选择",
                                                clearable: "",
                                                "value-format":
                                                  item["value-format"],
                                                format: item.format,
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : [
                                            "date",
                                            "createTime",
                                            "modifyTime",
                                          ].includes(item.jnpfKey)
                                        ? [
                                            _c("el-date-picker", {
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请选择",
                                                type:
                                                  item.jnpfKey === "date" &&
                                                  item.type
                                                    ? item.type
                                                    : "datetime",
                                                "value-format": "timestamp",
                                                format:
                                                  item.format ||
                                                  "yyyy-MM-dd HH:mm:ss",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionDateChange(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : [
                                            "comSelect",
                                            "currOrganize",
                                          ].includes(item.jnpfKey)
                                        ? [
                                            _c("comSelect", {
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionListChange(
                                                    arguments,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : ["depSelect"].includes(item.jnpfKey)
                                        ? [
                                            _c("depSelect", {
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionObjChange(
                                                    arguments,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : [
                                            "userSelect",
                                            "createUser",
                                            "modifyUser",
                                          ].includes(item.jnpfKey)
                                        ? [
                                            _c("userSelect", {
                                              attrs: {
                                                placeholder: "请选择",
                                                hasSys: "",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionObjChange(
                                                    arguments,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : ["usersSelect"].includes(item.jnpfKey)
                                        ? [
                                            _c("usersSelect", {
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionObjChange(
                                                    arguments,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : [
                                            "posSelect",
                                            "currPosition",
                                          ].includes(item.jnpfKey)
                                        ? [
                                            _c("posSelect", {
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionObjChange(
                                                    arguments,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : item.jnpfKey === "address"
                                        ? [
                                            _c("SNOWSAddress", {
                                              attrs: {
                                                placeholder: "请选择",
                                                level: item.level,
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionListChange(
                                                    arguments,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : item.jnpfKey === "groupSelect"
                                        ? [
                                            _c("groupSelect", {
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionObjChange(
                                                    arguments,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : item.jnpfKey === "roleSelect"
                                        ? [
                                            _c("roleSelect", {
                                              attrs: {
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onConditionObjChange(
                                                    arguments,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ]
                                        : [
                                            _c("el-input", {
                                              attrs: { placeholder: "请输入" },
                                              model: {
                                                value: item.fieldValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "fieldValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.fieldValue",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              item.fieldValueType === 1
                                ? _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.fieldValueChange(
                                            $event,
                                            item
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.fieldValue,
                                        callback: function ($$v) {
                                          _vm.$set(item, "fieldValue", $$v)
                                        },
                                        expression: "item.fieldValue",
                                      },
                                    },
                                    _vm._l(_vm.usedFormItems, function (item) {
                                      return _c("el-option", {
                                        key: item.__vModel__,
                                        attrs: {
                                          label: item.__config__.label,
                                          value: item.__vModel__,
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "condition-select",
                                  attrs: { placeholder: "请选择" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.logicChange($event, item)
                                    },
                                  },
                                  model: {
                                    value: item.logic,
                                    callback: function ($$v) {
                                      _vm.$set(item, "logic", $$v)
                                    },
                                    expression: "item.logic",
                                  },
                                },
                                _vm._l(_vm.logicOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 1 } }, [
                            _c("i", {
                              staticClass: "el-icon-delete",
                              on: {
                                click: function ($event) {
                                  return _vm.onDelCondition(index)
                                },
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ]
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-left": "4px",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.addCondition()
                            },
                          },
                        },
                        [_vm._v("添加条件")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.value && _vm.isTimerNode()
            ? _c(
                "section",
                { staticClass: "condition-pane pd-10-20" },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-alert", {
                        attrs: {
                          title: "定时器将根据设置的延迟时间流转审批",
                          type: "warning",
                          closable: false,
                          "show-icon": "",
                        },
                      }),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form",
                            {
                              staticClass: "mt-10",
                              attrs: { "label-position": "top" },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "时间设置" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-item-label",
                                      attrs: { slot: "label" },
                                      slot: "label",
                                    },
                                    [_vm._v("延迟时间")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-item-content" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mb-10" },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              min: 0,
                                              precision: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.properties.day,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.properties,
                                                  "day",
                                                  $$v
                                                )
                                              },
                                              expression: "properties.day",
                                            },
                                          }),
                                          _c("span", { staticClass: "ml-8" }, [
                                            _vm._v("天"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mb-10" },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              min: 0,
                                              precision: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.properties.hour,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.properties,
                                                  "hour",
                                                  $$v
                                                )
                                              },
                                              expression: "properties.hour",
                                            },
                                          }),
                                          _c("span", { staticClass: "ml-8" }, [
                                            _vm._v("小时"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mb-10" },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              min: 0,
                                              precision: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.properties.minute,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.properties,
                                                  "minute",
                                                  $$v
                                                )
                                              },
                                              expression: "properties.minute",
                                            },
                                          }),
                                          _c("span", { staticClass: "ml-8" }, [
                                            _vm._v("分钟"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              min: 0,
                                              precision: 0,
                                              "controls-position": "right",
                                            },
                                            model: {
                                              value: _vm.properties.second,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.properties,
                                                  "second",
                                                  $$v
                                                )
                                              },
                                              expression: "properties.second",
                                            },
                                          }),
                                          _c("span", { staticClass: "ml-8" }, [
                                            _vm._v("秒"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value && _vm.isSubFlowNode()
            ? _c(
                "section",
                { staticClass: "approver-pane" },
                [
                  _c(
                    "el-tabs",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础设置" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    "label-position": "top",
                                    model: _vm.subFlowForm,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "发起设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("发起设置")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { change: _vm.resetOrgColl },
                                              model: {
                                                value:
                                                  _vm.subFlowForm.initiateType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.subFlowForm,
                                                    "initiateType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "subFlowForm.initiateType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.initiateTypeOptions,
                                              function (item) {
                                                return _c(
                                                  "el-radio",
                                                  {
                                                    key: item.value,
                                                    staticClass: "radio-item",
                                                    attrs: {
                                                      label: item.value,
                                                      disabled: item.disabled,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.label) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _vm.subFlowForm.initiateType === 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  发起者的主管将作为子流程发起人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  发起者的部门主管将作为子流程发起人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 3
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  发起者自己将作为子流程发起人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 4
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  选择表单字段的值作为子流程发起人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 5
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  设置审批流程中某个环节的审批人作为子流程发起人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 6
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  所指定的成员将作为子流程发起人，多人时，发起多个子流程"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 7
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  指定可供选择的候选人发起多个子流程"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 9
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  从目标服务中获取子流程发起人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 1
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                  attrs: { label: "发起者的" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.subFlowForm
                                                            .managerLevel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.subFlowForm,
                                                            "managerLevel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "subFlowForm.managerLevel",
                                                      },
                                                    },
                                                    _vm._l(10, function (item) {
                                                      return _c("el-option", {
                                                        key: item,
                                                        attrs: {
                                                          label:
                                                            item === 1
                                                              ? "直接主管"
                                                              : "第" +
                                                                item +
                                                                "级主管",
                                                          value: item,
                                                        },
                                                      })
                                                    }),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 2
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                  attrs: { label: "发起者的" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.subFlowForm
                                                            .departmentLevel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.subFlowForm,
                                                            "departmentLevel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "subFlowForm.departmentLevel",
                                                      },
                                                    },
                                                    _vm._l(10, function (item) {
                                                      return _c("el-option", {
                                                        key: item,
                                                        attrs: {
                                                          label:
                                                            "第" +
                                                            item +
                                                            "级部门主管",
                                                          value: item,
                                                        },
                                                      })
                                                    }),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 4
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                  attrs: { label: "表单字段" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "assignee-form",
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "form-field-type",
                                                          attrs: {
                                                            placeholder:
                                                              "请选择字段",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.subFlowForm
                                                                .formFieldType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.subFlowForm,
                                                                "formFieldType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "subFlowForm.formFieldType",
                                                          },
                                                        },
                                                        [
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 1,
                                                              label: "用户",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 2,
                                                              label: "部门",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 3,
                                                              label: "岗位",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 4,
                                                              label: "角色",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 5,
                                                              label: "分组",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "form-field",
                                                          attrs: {
                                                            placeholder:
                                                              "请选择字段",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.subFlowForm
                                                                .formField,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.subFlowForm,
                                                                "formField",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "subFlowForm.formField",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.usedFormItems,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.__vModel__,
                                                                attrs: {
                                                                  label:
                                                                    item
                                                                      .__config__
                                                                      .label,
                                                                  value:
                                                                    item.__vModel__,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 5
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                  attrs: { label: "审批节点" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择节点",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.subFlowForm
                                                            .nodeId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.subFlowForm,
                                                            "nodeId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "subFlowForm.nodeId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.nodeOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.nodeId,
                                                          attrs: {
                                                            label:
                                                              item.properties
                                                                .title,
                                                            value: item.nodeId,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 9
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: { slot: "label" },
                                                      slot: "label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请求路径\n                    "
                                                      ),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top",
                                                            content:
                                                              '请求自带参数：taskId、taskNodeId，返回结构：JSON对象{"handleId":"id1,id2"}',
                                                          },
                                                        },
                                                        [
                                                          _c("a", {
                                                            staticClass:
                                                              "el-icon-warning-outline",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请输入http或https接口地址",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.subFlowForm
                                                            .getUserUrl,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.subFlowForm,
                                                            "getUserUrl",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "subFlowForm.getUserUrl",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "prepend" },
                                                        [_vm._v("GET")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.subFlowForm.initiateType === 6 ||
                                          _vm.subFlowForm.initiateType === 7
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                },
                                                [
                                                  _c("org-select", {
                                                    ref: "subFlow-department-org",
                                                    staticClass: "mb-5",
                                                    attrs: {
                                                      type: "department",
                                                      title: "添加部门",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.subFlowForm
                                                          .initiateOrg,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.subFlowForm,
                                                          "initiateOrg",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "subFlowForm.initiateOrg",
                                                    },
                                                  }),
                                                  _c("org-select", {
                                                    ref: "subFlow-role-org",
                                                    staticClass: "mb-5",
                                                    attrs: {
                                                      type: "role",
                                                      title: "添加角色",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.subFlowForm
                                                          .initiateRole,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.subFlowForm,
                                                          "initiateRole",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "subFlowForm.initiateRole",
                                                    },
                                                  }),
                                                  _c("org-select", {
                                                    ref: "subFlow-position-org",
                                                    staticClass: "mb-5",
                                                    attrs: {
                                                      title: "添加岗位",
                                                      type: "position",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.subFlowForm
                                                          .initiatePos,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.subFlowForm,
                                                          "initiatePos",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "subFlowForm.initiatePos",
                                                    },
                                                  }),
                                                  _c("org-select", {
                                                    ref: "subFlow-user-org",
                                                    attrs: {
                                                      title: "添加用户",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.subFlowForm
                                                          .initiator,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.subFlowForm,
                                                          "initiator",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "subFlowForm.initiator",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "子流程类型" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("子流程类型")]
                                      ),
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "form-item-content",
                                          model: {
                                            value: _vm.subFlowForm.isAsync,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.subFlowForm,
                                                "isAsync",
                                                $$v
                                              )
                                            },
                                            expression: "subFlowForm.isAsync",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: false } },
                                            [_vm._v("同步")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: true } },
                                            [_vm._v("异步")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "子流程表单" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("子流程表单")]
                                      ),
                                      _c("flow-dialog", {
                                        staticClass: "form-item-content",
                                        attrs: {
                                          value: _vm.subFlowForm.flowId,
                                          title: _vm.subFlowForm.flowName,
                                          flowType: _vm.flowType,
                                        },
                                        on: { change: _vm.onSubFlowIdChange },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "子流程传递" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("子流程传递")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content hand",
                                          on: { click: _vm.openRuleBox },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "hand",
                                            attrs: {
                                              value: _vm.subFlowForm.assignList
                                                .length
                                                ? "已设置"
                                                : "",
                                              placeholder:
                                                "请设置子流程传递规则",
                                              "suffix-icon":
                                                "el-icon-arrow-down",
                                              readonly: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("el-form-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-item-label",
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _vm._v("异常处理\n                "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "子流程发起节点人员异常时遵循该规则",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("a", {
                                              staticClass:
                                                "el-icon-warning-outline",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-item-content" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "mb-10",
                                            on: {
                                              change: function ($event) {
                                                _vm.subFlowForm.errorRuleUser =
                                                  []
                                              },
                                            },
                                            model: {
                                              value: _vm.subFlowForm.errorRule,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.subFlowForm,
                                                  "errorRule",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "subFlowForm.errorRule",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "超级管理员",
                                                value: 1,
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "指定人员",
                                                value: 2,
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "发起者本人",
                                                value: 6,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.subFlowForm.errorRule === 2
                                          ? _c("org-select", {
                                              attrs: {
                                                type: "user",
                                                title: "选择用户",
                                                buttonType: "button",
                                              },
                                              model: {
                                                value:
                                                  _vm.subFlowForm.errorRuleUser,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.subFlowForm,
                                                    "errorRuleUser",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "subFlowForm.errorRuleUser",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "节点通知" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    model: _vm.subFlowForm,
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c("el-alert", {
                                    attrs: {
                                      title:
                                        "该通知设置在【消息中心】-【消息发送配置】维护，选择默认则站内信提醒，选择关闭则不提醒。",
                                      type: "warning",
                                      closable: false,
                                      "show-icon": "",
                                    },
                                  }),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mt-10" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v(
                                            "子流程发起\n                "
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "该子流程被发起的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.subFlowForm.launchMsgConfig
                                                .on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.subFlowForm.launchMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "subFlowForm.launchMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.subFlowForm.launchMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: " 发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.subFlowForm
                                                      .launchMsgConfig.msgId,
                                                  title:
                                                    _vm.subFlowForm
                                                      .launchMsgConfig.msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "subFlowForm",
                                                      "launchMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("el-form-item", {
                                            staticStyle: {
                                              "margin-bottom": "0",
                                            },
                                            attrs: { label: "参数设置" },
                                          }),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.subFlowForm
                                                  .launchMsgConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.funcOptions,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.__vModel__,
                                                                      attrs: {
                                                                        label:
                                                                          item
                                                                            .__config__
                                                                            .label
                                                                            ? item.__vModel__ +
                                                                              "(" +
                                                                              item
                                                                                .__config__
                                                                                .label +
                                                                              ")"
                                                                            : item.__vModel__,
                                                                        value:
                                                                          item.__vModel__,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  865070175
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value && _vm.isStartNode()
            ? _c(
                "section",
                { staticClass: "approver-pane" },
                [
                  _c(
                    "el-tabs",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础设置" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    "label-position": "top",
                                    model: _vm.startForm,
                                  },
                                },
                                [
                                  _c("el-form-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-item-label",
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.flowType == 1 ? "功能" : "表单"
                                          ) + "设置"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-item-content" },
                                      [
                                        _c("flow-form-dialog", {
                                          attrs: {
                                            value: _vm.startForm.formId,
                                            title: _vm.startForm.formName,
                                            type: _vm.flowType,
                                            clearable: false,
                                            placeholder:
                                              "请选择" +
                                              (_vm.flowType == 1
                                                ? "功能"
                                                : "表单"),
                                          },
                                          on: {
                                            change: _vm.onStartFormIdChange,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm.flowType != 1
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "发起设置" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-item-label",
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [
                                              _vm._v(
                                                "发起设置\n                "
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "谁可以发起 默认所有人,需要设置请选择",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("a", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-item-content",
                                            },
                                            [
                                              _c("org-select", {
                                                ref: "start-department-org",
                                                staticClass: "mb-5",
                                                attrs: {
                                                  type: "department",
                                                  title: "添加部门",
                                                },
                                                model: {
                                                  value: _vm.initiateOrg,
                                                  callback: function ($$v) {
                                                    _vm.initiateOrg = $$v
                                                  },
                                                  expression: "initiateOrg",
                                                },
                                              }),
                                              _c("org-select", {
                                                ref: "start-role-org",
                                                staticClass: "mb-5",
                                                attrs: {
                                                  type: "role",
                                                  title: "添加角色",
                                                },
                                                model: {
                                                  value: _vm.initiateRole,
                                                  callback: function ($$v) {
                                                    _vm.initiateRole = $$v
                                                  },
                                                  expression: "initiateRole",
                                                },
                                              }),
                                              _c("org-select", {
                                                ref: "start-position-org",
                                                staticClass: "mb-5",
                                                attrs: {
                                                  type: "position",
                                                  title: "添加岗位",
                                                },
                                                model: {
                                                  value: _vm.initiatePos,
                                                  callback: function ($$v) {
                                                    _vm.initiatePos = $$v
                                                  },
                                                  expression: "initiatePos",
                                                },
                                              }),
                                              _c("org-select", {
                                                ref: "start-user-org",
                                                attrs: {
                                                  type: "user",
                                                  title: "添加用户",
                                                },
                                                model: {
                                                  value: _vm.initiator,
                                                  callback: function ($$v) {
                                                    _vm.initiator = $$v
                                                  },
                                                  expression: "initiator",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "抄送设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("抄送设置")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c("org-select", {
                                            ref: "start-copy-department-org",
                                            staticClass: "mb-5",
                                            attrs: {
                                              type: "department",
                                              title: "添加部门",
                                            },
                                            model: {
                                              value: _vm.startForm.circulateOrg,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.startForm,
                                                  "circulateOrg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "startForm.circulateOrg",
                                            },
                                          }),
                                          _c("org-select", {
                                            ref: "start-copy-role-org",
                                            staticClass: "mb-5",
                                            attrs: {
                                              type: "role",
                                              title: "添加角色",
                                            },
                                            model: {
                                              value:
                                                _vm.startForm.circulateRole,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.startForm,
                                                  "circulateRole",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "startForm.circulateRole",
                                            },
                                          }),
                                          _c("org-select", {
                                            ref: "start-copy-position-org",
                                            staticClass: "mb-5",
                                            attrs: {
                                              title: "添加岗位",
                                              type: "position",
                                            },
                                            model: {
                                              value:
                                                _vm.startForm.circulatePosition,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.startForm,
                                                  "circulatePosition",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "startForm.circulatePosition",
                                            },
                                          }),
                                          _c("org-select", {
                                            ref: "start-copy-user-org",
                                            staticClass: "mb-5",
                                            attrs: { title: "添加用户" },
                                            model: {
                                              value:
                                                _vm.startForm.circulateUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.startForm,
                                                  "circulateUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "startForm.circulateUser",
                                            },
                                          }),
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _vm._v(
                                                    "抄送人范围\n                    "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "抄送人员增加人员选择范围附加条件",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-select",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .extraCopyRule,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm,
                                                        "extraCopyRule",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.extraCopyRule",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.extraRuleOptions,
                                                  function (item, i) {
                                                    return _c("el-option", {
                                                      key: i,
                                                      attrs: {
                                                        label:
                                                          item.value == 1
                                                            ? "无抄送人范围"
                                                            : item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-checkbox",
                                            {
                                              model: {
                                                value:
                                                  _vm.startForm.isCustomCopy,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.startForm,
                                                    "isCustomCopy",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "startForm.isCustomCopy",
                                              },
                                            },
                                            [_vm._v("允许自选抄送人")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "高级设置" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: { "label-position": "top" },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "操作设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("操作设置")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .hasSubmitBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm,
                                                        "hasSubmitBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.hasSubmitBtn",
                                                  },
                                                },
                                                [_vm._v("提交")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.startForm.submitBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.startForm,
                                                      "submitBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "startForm.submitBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.startForm.hasSaveBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm,
                                                        "hasSaveBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.hasSaveBtn",
                                                  },
                                                },
                                                [_vm._v("暂存")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.startForm.saveBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.startForm,
                                                      "saveBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "startForm.saveBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .hasRevokeBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm,
                                                        "hasRevokeBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.hasRevokeBtn",
                                                  },
                                                },
                                                [_vm._v("撤回")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.startForm.revokeBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.startForm,
                                                      "revokeBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "startForm.revokeBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm.hasPressBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm,
                                                        "hasPressBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.hasPressBtn",
                                                  },
                                                },
                                                [_vm._v("催办")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.startForm.pressBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.startForm,
                                                      "pressBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "startForm.pressBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm.hasPrintBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm,
                                                        "hasPrintBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.hasPrintBtn",
                                                  },
                                                },
                                                [_vm._v("打印")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.startForm.printBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.startForm,
                                                      "printBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "startForm.printBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.startForm.hasPrintBtn
                                            ? _c(
                                                "div",
                                                { staticClass: "per-cell" },
                                                [
                                                  _c("p", {
                                                    staticStyle: {
                                                      width: "112px",
                                                    },
                                                  }),
                                                  _c("SNOWS-TreeSelect", {
                                                    attrs: {
                                                      options: _vm.printTplList,
                                                      placeholder:
                                                        "请选择打印模板",
                                                      lastLevel: "",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.startForm.printId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.startForm,
                                                          "printId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "startForm.printId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "标题设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("标题设置")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: _vm.startForm.titleType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.startForm,
                                                    "titleType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "startForm.titleType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("默认")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("自定义")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.startForm.titleType == 0
                                            ? [
                                                _c("el-input", {
                                                  staticClass: "mt-8",
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    readOnly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .defaultContent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm,
                                                        "defaultContent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.defaultContent",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _vm.startForm.titleType == 1
                                            ? [
                                                _c("el-autocomplete", {
                                                  staticClass: "mt-8",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    clearable: "",
                                                    "fetch-suggestions":
                                                      _vm.querySearch,
                                                  },
                                                  on: {
                                                    select: _vm.handleSelect,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .titleContent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm,
                                                        "titleContent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.titleContent",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _c("el-form-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-item-label",
                                        attrs: { slot: "label" },
                                        slot: "label",
                                      },
                                      [
                                        _vm._v("异常处理\n                "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "审批节点内设置的审批人员异常时遵循该规则",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("a", {
                                              staticClass:
                                                "el-icon-warning-outline",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-item-content" },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            on: {
                                              change: function ($event) {
                                                _vm.startForm.errorRuleUser = []
                                              },
                                            },
                                            model: {
                                              value: _vm.startForm.errorRule,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.startForm,
                                                  "errorRule",
                                                  $$v
                                                )
                                              },
                                              expression: "startForm.errorRule",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "超级管理员",
                                                value: 1,
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "指定人员",
                                                value: 2,
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "上一节点审批人指定",
                                                value: 3,
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "默认审批通过",
                                                value: 4,
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "无法提交",
                                                value: 5,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.startForm.errorRule === 2
                                          ? _c("org-select", {
                                              staticClass: "mt-10",
                                              attrs: {
                                                type: "user",
                                                title: "选择用户",
                                                buttonType: "button",
                                              },
                                              model: {
                                                value:
                                                  _vm.startForm.errorRuleUser,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.startForm,
                                                    "errorRuleUser",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "startForm.errorRuleUser",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  staticStyle: { "margin-top": "-20px" },
                                  attrs: {
                                    "label-width": "90px",
                                    "label-position": "left",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("手写签名\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "发起人在进行流程撤回操作时需手写签名",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.startForm.hasSign,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.startForm,
                                              "hasSign",
                                              $$v
                                            )
                                          },
                                          expression: "startForm.hasSign",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("意见填写\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "发起人在进行流程撤回操作需填写意见",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.startForm.hasOpinion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.startForm,
                                              "hasOpinion",
                                              $$v
                                            )
                                          },
                                          expression: "startForm.hasOpinion",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("审核汇总\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "流程流转记录会按部门及岗位进行汇总展示",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.startForm.isSummary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.startForm,
                                              "isSummary",
                                              $$v
                                            )
                                          },
                                          expression: "startForm.isSummary",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.isSummary
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("汇总设置")]
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "请选择" },
                                              model: {
                                                value:
                                                  _vm.startForm.summaryType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.startForm,
                                                    "summaryType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "startForm.summaryType",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "汇总全部流转记录",
                                                  value: 0,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label:
                                                    "汇总通过及退回流转记录",
                                                  value: 1,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "流程评论" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("流程评论\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "流程内涉及的用户均可进行意见评论",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.startForm.isComment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.startForm,
                                              "isComment",
                                              $$v
                                            )
                                          },
                                          expression: "startForm.isComment",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "批量审批" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("批量审批\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "流程待审批工单可进行批量操作",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.startForm.isBatchApproval,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.startForm,
                                              "isBatchApproval",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "startForm.isBatchApproval",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-tab-pane", { attrs: { label: "表单权限1" } }, [
                        _c(
                          "div",
                          { staticClass: "form-auth-table" },
                          [
                            _c(
                              "el-table",
                              {
                                staticClass: "SNOWS-common-table",
                                attrs: {
                                  data: _vm.getFormOperates(),
                                  size: "mini",
                                  height: "100%",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "name",
                                    label: "表单字段",
                                    align: "left",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "write",
                                    label: "操作",
                                    align: "center",
                                    width: "250",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-checkbox",
                                              {
                                                model: {
                                                  value: scope.row.read,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "read",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "scope.row.read",
                                                },
                                              },
                                              [_vm._v("查看")]
                                            ),
                                            _c(
                                              "el-checkbox",
                                              {
                                                model: {
                                                  value: scope.row.write,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "write",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "scope.row.write",
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            ),
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  disabled:
                                                    scope.row.requiredDisabled,
                                                },
                                                model: {
                                                  value: scope.row.required,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "required",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.required",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  必填"
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    883505694
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "流程事件" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    model: _vm.startForm,
                                    "label-position": "left",
                                  },
                                },
                                [
                                  _c("el-alert", {
                                    attrs: {
                                      title:
                                        "开启后可配置触发事件同时进行参数赋值",
                                      type: "warning",
                                      closable: false,
                                      "show-icon": "",
                                    },
                                  }),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mt-10",
                                      attrs: { label: "发起事件" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("发起事件")]
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.startForm.initFuncConfig.on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.startForm.initFuncConfig,
                                              "on",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "startForm.initFuncConfig.on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.initFuncConfig.on
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("接口设置")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c("interface-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm.initFuncConfig
                                                      .interfaceId,
                                                  title:
                                                    _vm.startForm.initFuncConfig
                                                      .interfaceName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onFuncChange(
                                                      "startForm",
                                                      "initFuncConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .initFuncConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "200",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required-sign",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .required
                                                                    ? "*"
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .fieldName
                                                                  ? scope.row
                                                                      .field +
                                                                      "(" +
                                                                      scope.row
                                                                        .fieldName +
                                                                      ")"
                                                                  : scope.row
                                                                      .field
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  97883127
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择表单字段",
                                                                clearable: "",
                                                                filterable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRelationFieldChange(
                                                                      $event,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .relationField,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "relationField",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.relationField",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option-group",
                                                                _vm._l(
                                                                  _vm.systemFieldOptions,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.__vModel__,
                                                                        attrs: {
                                                                          label:
                                                                            item
                                                                              .__config__
                                                                              .label
                                                                              ? item.__vModel__ +
                                                                                "(" +
                                                                                item
                                                                                  .__config__
                                                                                  .label +
                                                                                ")"
                                                                              : item.__vModel__,
                                                                          value:
                                                                            item.__vModel__,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              scope.row.required
                                                                ? _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.funcRequiredOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.formFieldsOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  542453351
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "结束事件" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("结束事件")]
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.startForm.endFuncConfig.on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.startForm.endFuncConfig,
                                              "on",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "startForm.endFuncConfig.on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.endFuncConfig.on
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("接口设置")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c("interface-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm.endFuncConfig
                                                      .interfaceId,
                                                  title:
                                                    _vm.startForm.endFuncConfig
                                                      .interfaceName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onFuncChange(
                                                      "startForm",
                                                      "endFuncConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .endFuncConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "200",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required-sign",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .required
                                                                    ? "*"
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .fieldName
                                                                  ? scope.row
                                                                      .field +
                                                                      "(" +
                                                                      scope.row
                                                                        .fieldName +
                                                                      ")"
                                                                  : scope.row
                                                                      .field
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  97883127
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择表单字段",
                                                                clearable: "",
                                                                filterable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRelationFieldChange(
                                                                      $event,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .relationField,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "relationField",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.relationField",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option-group",
                                                                _vm._l(
                                                                  _vm.systemFieldOptions,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.__vModel__,
                                                                        attrs: {
                                                                          label:
                                                                            item
                                                                              .__config__
                                                                              .label
                                                                              ? item.__vModel__ +
                                                                                "(" +
                                                                                item
                                                                                  .__config__
                                                                                  .label +
                                                                                ")"
                                                                              : item.__vModel__,
                                                                          value:
                                                                            item.__vModel__,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              scope.row.required
                                                                ? _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.funcRequiredOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.formFieldsOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  542453351
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "撤回事件" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("撤回事件")]
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.startForm.flowRecallFuncConfig
                                              .on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.startForm
                                                .flowRecallFuncConfig,
                                              "on",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "startForm.flowRecallFuncConfig.on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.flowRecallFuncConfig.on
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("接口设置")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c("interface-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm
                                                      .flowRecallFuncConfig
                                                      .interfaceId,
                                                  title:
                                                    _vm.startForm
                                                      .flowRecallFuncConfig
                                                      .interfaceName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onFuncChange(
                                                      "startForm",
                                                      "flowRecallFuncConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .flowRecallFuncConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "200",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required-sign",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .required
                                                                    ? "*"
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .fieldName
                                                                  ? scope.row
                                                                      .field +
                                                                      "(" +
                                                                      scope.row
                                                                        .fieldName +
                                                                      ")"
                                                                  : scope.row
                                                                      .field
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  97883127
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择表单字段",
                                                                clearable: "",
                                                                filterable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRelationFieldChange(
                                                                      $event,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .relationField,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "relationField",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.relationField",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option-group",
                                                                _vm._l(
                                                                  _vm.systemFieldOptions,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.__vModel__,
                                                                        attrs: {
                                                                          label:
                                                                            item
                                                                              .__config__
                                                                              .label
                                                                              ? item.__vModel__ +
                                                                                "(" +
                                                                                item
                                                                                  .__config__
                                                                                  .label +
                                                                                ")"
                                                                              : item.__vModel__,
                                                                          value:
                                                                            item.__vModel__,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              scope.row.required
                                                                ? _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.funcRequiredOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.formFieldsOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  542453351
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "流程通知" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    model: _vm.startForm,
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c("el-alert", {
                                    attrs: {
                                      title:
                                        "该通知设置在【消息中心】-【消息发送配置】维护，选择默认则站内信提醒，选择关闭则不提醒。",
                                      type: "warning",
                                      closable: false,
                                      "show-icon": "",
                                    },
                                  }),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mt-10" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("流程待办\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "流程处于等待的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.waitMsgConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.waitMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.waitMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.waitMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm.waitMsgConfig
                                                      .msgId,
                                                  title:
                                                    _vm.startForm.waitMsgConfig
                                                      .msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "startForm",
                                                      "waitMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .waitMsgConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.systemFieldOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.formFieldsOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  848379261
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("流程结束\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "流程结束的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.endMsgConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.endMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.endMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.endMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm.endMsgConfig
                                                      .msgId,
                                                  title:
                                                    _vm.startForm.endMsgConfig
                                                      .msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "startForm",
                                                      "endMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm.endMsgConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.systemFieldOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.formFieldsOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  848379261
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点同意\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "所有节点审核人同意的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.approveMsgConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.approveMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.approveMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.approveMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm
                                                      .approveMsgConfig.msgId,
                                                  title:
                                                    _vm.startForm
                                                      .approveMsgConfig.msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "startForm",
                                                      "approveMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .approveMsgConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.systemFieldOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.formFieldsOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  848379261
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点退回\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "所有节点审核人退回的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.rejectMsgConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.rejectMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.rejectMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.rejectMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm
                                                      .rejectMsgConfig.msgId,
                                                  title:
                                                    _vm.startForm
                                                      .rejectMsgConfig.msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "startForm",
                                                      "rejectMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .rejectMsgConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.systemFieldOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.formFieldsOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  848379261
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点抄送\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "所有节点抄送的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.copyMsgConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.copyMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.copyMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.copyMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm.copyMsgConfig
                                                      .msgId,
                                                  title:
                                                    _vm.startForm.copyMsgConfig
                                                      .msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "startForm",
                                                      "copyMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .copyMsgConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.systemFieldOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.formFieldsOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  848379261
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点超时\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "所有节点超时的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.overTimeMsgConfig
                                                .on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.overTimeMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.overTimeMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.overTimeMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm
                                                      .overTimeMsgConfig.msgId,
                                                  title:
                                                    _vm.startForm
                                                      .overTimeMsgConfig
                                                      .msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "startForm",
                                                      "overTimeMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .overTimeMsgConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.funcOptions,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.__vModel__,
                                                                      attrs: {
                                                                        label:
                                                                          item
                                                                            .__config__
                                                                            .label
                                                                            ? item.__vModel__ +
                                                                              "(" +
                                                                              item
                                                                                .__config__
                                                                                .label +
                                                                              ")"
                                                                            : item.__vModel__,
                                                                        value:
                                                                          item.__vModel__,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  865070175
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点提醒\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "所有节点提醒的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.noticeMsgConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.noticeMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.noticeMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.noticeMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.startForm
                                                      .noticeMsgConfig.msgId,
                                                  title:
                                                    _vm.startForm
                                                      .noticeMsgConfig.msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "startForm",
                                                      "noticeMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.startForm
                                                  .noticeMsgConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.funcOptions,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.__vModel__,
                                                                      attrs: {
                                                                        label:
                                                                          item
                                                                            .__config__
                                                                            .label
                                                                            ? item.__vModel__ +
                                                                              "(" +
                                                                              item
                                                                                .__config__
                                                                                .label +
                                                                              ")"
                                                                            : item.__vModel__,
                                                                        value:
                                                                          item.__vModel__,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  865070175
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "超时处理" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    model: _vm.startForm,
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "限时设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("限时设置")]
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.timeLimitConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.timeLimitConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.timeLimitConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.timeLimitConfig.on === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "节点限定时长起始值",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .timeLimitConfig
                                                        .nodeLimit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm
                                                          .timeLimitConfig,
                                                        "nodeLimit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.timeLimitConfig.nodeLimit",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.overTimeOptions,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("节点处理限定时长(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 1, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.startForm
                                                          .timeLimitConfig
                                                          .duringDeal,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.startForm
                                                            .timeLimitConfig,
                                                          "duringDeal",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "startForm.timeLimitConfig.duringDeal",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mt-10" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("超时设置\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "超过设置的节点处理限定时间即为超时",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.overTimeConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.overTimeConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.overTimeConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.overTimeConfig.on === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("第一次超时时间(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 0, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.startForm
                                                          .overTimeConfig
                                                          .firstOver,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.startForm
                                                            .overTimeConfig,
                                                          "firstOver",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "startForm.overTimeConfig.firstOver",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            {
                                              staticClass: "mt-10",
                                              attrs: { gutter: 20 },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("超时间隔(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 0, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.startForm
                                                          .overTimeConfig
                                                          .overTimeDuring,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.startForm
                                                            .overTimeConfig,
                                                          "overTimeDuring",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "startForm.overTimeConfig.overTimeDuring",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("el-row", [_vm._v("超时事务")]),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .overTimeConfig
                                                        .overNotice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm
                                                          .overTimeConfig,
                                                        "overNotice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.overTimeConfig.overNotice",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "超时通知\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "勾选后才能进行超时消息发送（站内信系统默认发送，第三方超时消息需在流程通知内配置）",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .overTimeConfig
                                                        .overAutoApprove,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm
                                                          .overTimeConfig,
                                                        "overAutoApprove",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.overTimeConfig.overAutoApprove",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "超时自动审批\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "当前审批节点表单必填字段为空会使工单流转失败，下一审批节点设置候选人员、选择分支时当前审批节点规则失效",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("超时次数(次)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 1, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.startForm
                                                          .overTimeConfig
                                                          .overAutoApproveTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.startForm
                                                            .overTimeConfig,
                                                          "overAutoApproveTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "startForm.overTimeConfig.overAutoApproveTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm
                                                        .overTimeConfig
                                                        .overEvent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm
                                                          .overTimeConfig,
                                                        "overEvent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.overTimeConfig.overEvent",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "超时事件\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "请在流程事件内配置超时事件",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("超时次数(次)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 1, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.startForm
                                                          .overTimeConfig
                                                          .overEventTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.startForm
                                                            .overTimeConfig,
                                                          "overEventTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "startForm.overTimeConfig.overEventTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mt-10" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("提醒设置\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "还未到达设置的节点处理限定时间即为提醒",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.startForm.noticeConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.startForm.noticeConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "startForm.noticeConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.noticeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.startForm.noticeConfig.on === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("第一次提醒时间(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 1, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.startForm
                                                          .noticeConfig
                                                          .firstOver,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.startForm
                                                            .noticeConfig,
                                                          "firstOver",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "startForm.noticeConfig.firstOver",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            {
                                              staticClass: "mt-10",
                                              attrs: { gutter: 20 },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("提醒间隔(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 0, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.startForm
                                                          .noticeConfig
                                                          .overTimeDuring,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.startForm
                                                            .noticeConfig,
                                                          "overTimeDuring",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "startForm.noticeConfig.overTimeDuring",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("el-row", [_vm._v("提醒事务")]),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm.noticeConfig
                                                        .overNotice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm
                                                          .noticeConfig,
                                                        "overNotice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.noticeConfig.overNotice",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "提醒通知\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "勾选后才能进行提醒消息发送（站内信系统默认发送，第三方超时消息需在流程通知内配置）",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.startForm.noticeConfig
                                                        .overEvent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.startForm
                                                          .noticeConfig,
                                                        "overEvent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "startForm.noticeConfig.overEvent",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "提醒事件\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "请在流程事件内配置提醒事件",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-row",
                                                { attrs: { gutter: 20 } },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [_vm._v("提醒次数(次)")]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 16 } },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          min: 1,
                                                          step: 1,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.startForm
                                                              .noticeConfig
                                                              .overEventTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.startForm
                                                                .noticeConfig,
                                                              "overEventTime",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "startForm.noticeConfig.overEventTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.value && _vm.isApproverNode()
            ? _c(
                "section",
                { staticClass: "approver-pane" },
                [
                  _c(
                    "el-tabs",
                    {
                      staticStyle: { height: "100%" },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "基础设置", name: "config" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    "label-position": "top",
                                    model: _vm.approverForm,
                                  },
                                },
                                [
                                  _vm.flowType !== 1
                                    ? [
                                        _c("el-form-item", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-item-label",
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [
                                              _vm._v(
                                                "表单配置\n                  "
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "审批节点不配置表单，默认引用发起节点表单",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("a", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-item-content",
                                            },
                                            [
                                              _c("flow-form-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm.formId,
                                                  title:
                                                    _vm.approverForm.formName,
                                                  type: _vm.flowType,
                                                  placeholder: "请选择表单",
                                                },
                                                on: {
                                                  change:
                                                    _vm.onApproverFormIdChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("el-form-item", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-item-label",
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [
                                              _vm._v(
                                                "数据传递\n                  "
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "不设置传递规则时字段名称相同自动赋值；设置传递规则时相同名称字段会自动赋值字段后再按传递规则赋值",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("a", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-item-content hand",
                                              on: {
                                                click:
                                                  _vm.openApproverTransmitRuleBox,
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "hand",
                                                attrs: {
                                                  value: _vm.approverForm
                                                    .assignList.length
                                                    ? "已设置"
                                                    : "",
                                                  placeholder:
                                                    "请设置数据传递规则",
                                                  "suffix-icon":
                                                    "el-icon-arrow-down",
                                                  readonly: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "审批设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("审批设置")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { change: _vm.resetOrgColl },
                                              model: {
                                                value:
                                                  _vm.approverForm.assigneeType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.approverForm,
                                                    "assigneeType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "approverForm.assigneeType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.assigneeTypeOptions,
                                              function (item) {
                                                return _c(
                                                  "el-radio",
                                                  {
                                                    key: item.value,
                                                    staticClass: "radio-item",
                                                    attrs: {
                                                      label: item.value,
                                                      disabled: item.disabled,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.label) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _vm.approverForm.assigneeType === 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  发起者主管将作为审批人处理审批单"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  发起者的部门主管将作为审批人处理审批单"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 3
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  发起者自己将作为审批人处理审批单"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 4
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  选择流程表单字段的值作为审批人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 5
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  设置审批人为审批流程中某个环节的审批人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 6
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  指定审批人处理审批单"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 7
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  默认所有人，需要设置请指定候选人范围处理审批单"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 9
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "option-box-tip",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  从目标服务中获取审批人"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 1
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                  attrs: { label: "发起者的" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.approverForm
                                                            .managerLevel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.approverForm,
                                                            "managerLevel",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "approverForm.managerLevel",
                                                      },
                                                    },
                                                    _vm._l(10, function (item) {
                                                      return _c("el-option", {
                                                        key: item,
                                                        attrs: {
                                                          label:
                                                            item === 1
                                                              ? "直属主管"
                                                              : "第" +
                                                                item +
                                                                "级主管",
                                                          value: item,
                                                        },
                                                      })
                                                    }),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 4
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "0",
                                                  },
                                                  attrs: { label: "表单字段" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "assignee-form",
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "form-field-type",
                                                          attrs: {
                                                            placeholder:
                                                              "请选择字段",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.approverForm
                                                                .formFieldType,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.approverForm,
                                                                "formFieldType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "approverForm.formFieldType",
                                                          },
                                                        },
                                                        [
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 1,
                                                              label: "用户",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 2,
                                                              label: "部门",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 3,
                                                              label: "岗位",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              value: 4,
                                                              label: "角色",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticClass:
                                                            "form-field",
                                                          attrs: {
                                                            placeholder:
                                                              "请选择字段",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.approverForm
                                                                .formField,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.approverForm,
                                                                "formField",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "approverForm.formField",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.usedFormItems,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.__vModel__,
                                                                attrs: {
                                                                  label:
                                                                    item
                                                                      .__config__
                                                                      .label,
                                                                  value:
                                                                    item.__vModel__,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 5
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                  attrs: { label: "审批节点" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择节点",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.approverForm
                                                            .nodeId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.approverForm,
                                                            "nodeId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "approverForm.nodeId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.nodeOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.nodeId,
                                                          attrs: {
                                                            label:
                                                              item.properties
                                                                .title,
                                                            value: item.nodeId,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 9
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: { slot: "label" },
                                                      slot: "label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "请求路径\n                    "
                                                      ),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            placement: "top",
                                                            content:
                                                              '请求自带参数：taskId、taskNodeId，返回结构：JSON对象{"handleId":"id1,id2"}',
                                                          },
                                                        },
                                                        [
                                                          _c("a", {
                                                            staticClass:
                                                              "el-icon-warning-outline",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请输入http或https接口地址",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.approverForm
                                                            .getUserUrl,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.approverForm,
                                                            "getUserUrl",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "approverForm.getUserUrl",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "prepend" },
                                                        [_vm._v("GET")]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 6 ||
                                          _vm.approverForm.assigneeType === 7
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                },
                                                [
                                                  _c("org-select", {
                                                    ref: "approver-department-org",
                                                    staticClass: "mb-5",
                                                    attrs: {
                                                      type: "department",
                                                      title: "添加部门",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .approverOrg,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm,
                                                          "approverOrg",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.approverOrg",
                                                    },
                                                  }),
                                                  _c("org-select", {
                                                    ref: "approver-role-org",
                                                    staticClass: "mb-5",
                                                    attrs: {
                                                      type: "role",
                                                      title: "添加角色",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .approverRole,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm,
                                                          "approverRole",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.approverRole",
                                                    },
                                                  }),
                                                  _c("org-select", {
                                                    ref: "approver-position-org",
                                                    staticClass: "mb-5",
                                                    attrs: {
                                                      title: "添加岗位",
                                                      type: "position",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .approverPos,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm,
                                                          "approverPos",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.approverPos",
                                                    },
                                                  }),
                                                  _c("org-select", {
                                                    ref: "approver-user-org",
                                                    attrs: {
                                                      title: "添加用户",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .approvers,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm,
                                                          "approvers",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.approvers",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.approverForm.assigneeType === 6
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0!important",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: { slot: "label" },
                                                      slot: "label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "审批人范围\n                    "
                                                      ),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            content:
                                                              "指定成员增加人员选择范围附加条件",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("a", {
                                                            staticClass:
                                                              "el-icon-warning-outline",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-select",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.approverForm
                                                            .extraRule,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.approverForm,
                                                            "extraRule",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "approverForm.extraRule",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.extraRuleOptions,
                                                      function (item, i) {
                                                        return _c("el-option", {
                                                          key: i,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "审批方式" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("审批方式")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: 0 },
                                              model: {
                                                value:
                                                  _vm.approverForm.counterSign,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.approverForm,
                                                    "counterSign",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "approverForm.counterSign",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  或签（一名审批人同意或退回即可）"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: 1 },
                                              model: {
                                                value:
                                                  _vm.approverForm.counterSign,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.approverForm,
                                                    "counterSign",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "approverForm.counterSign",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  会签（无序会签，当审批达到会签比例时，则该审批通过）"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: 2 },
                                              model: {
                                                value:
                                                  _vm.approverForm.counterSign,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.approverForm,
                                                    "counterSign",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "approverForm.counterSign",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  依次审批（按顺序依次审批）"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.approverForm.counterSign == 1
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "会签比例" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "form-item-label",
                                              attrs: { slot: "label" },
                                              slot: "label",
                                            },
                                            [
                                              _vm._v(
                                                "会签比例\n                "
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content: "会签通过比例",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("a", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "form-item-content",
                                              model: {
                                                value:
                                                  _vm.approverForm
                                                    .countersignRatio,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.approverForm,
                                                    "countersignRatio",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "approverForm.countersignRatio",
                                              },
                                            },
                                            _vm._l(10, function (item) {
                                              return _c("el-option", {
                                                key: item * 10,
                                                attrs: {
                                                  label: item * 10 + "%",
                                                  value: item * 10,
                                                },
                                              })
                                            }),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "退回设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("退回设置\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "纯表单流程设置退回到发起节点无效",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "form-item-content",
                                          on: { input: _vm.radioInput },
                                          model: {
                                            value: _vm.approverForm.rejectType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm,
                                                "rejectType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.rejectType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [
                                              _vm._v(
                                                "重新审批\n                  "
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "若流程为A->B->C,C退回至A，则C->A->B->C",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 2 } },
                                            [
                                              _vm._v(
                                                "从当前节点审批\n                  "
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content:
                                                      "若流程为A->B->C,C退回至A，则C->A->C",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.approverForm.rejectStep,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm,
                                                "rejectStep",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.rejectStep",
                                          },
                                        },
                                        _vm._l(
                                          _vm.rejectStepOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.nodeId,
                                              attrs: {
                                                label: item.properties.title,
                                                value: item.nodeId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "进度设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("进度设置")]
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value: _vm.approverForm.progress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm,
                                                "progress",
                                                $$v
                                              )
                                            },
                                            expression: "approverForm.progress",
                                          },
                                        },
                                        _vm._l(
                                          _vm.progressOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                label: item + "%",
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "抄送设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("抄送设置")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c("org-select", {
                                            ref: "approver-copy-department-org",
                                            staticClass: "mb-5",
                                            attrs: {
                                              type: "department",
                                              title: "添加部门",
                                            },
                                            model: {
                                              value:
                                                _vm.approverForm.circulateOrg,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.approverForm,
                                                  "circulateOrg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "approverForm.circulateOrg",
                                            },
                                          }),
                                          _c("org-select", {
                                            ref: "approver-copy-role-org",
                                            staticClass: "mb-5",
                                            attrs: {
                                              type: "role",
                                              title: "添加角色",
                                            },
                                            model: {
                                              value:
                                                _vm.approverForm.circulateRole,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.approverForm,
                                                  "circulateRole",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "approverForm.circulateRole",
                                            },
                                          }),
                                          _c("org-select", {
                                            ref: "approver-copy-position-org",
                                            staticClass: "mb-5",
                                            attrs: {
                                              title: "添加岗位",
                                              type: "position",
                                            },
                                            model: {
                                              value:
                                                _vm.approverForm
                                                  .circulatePosition,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.approverForm,
                                                  "circulatePosition",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "approverForm.circulatePosition",
                                            },
                                          }),
                                          _c("org-select", {
                                            ref: "approver-copy-user-org",
                                            staticClass: "mb-5",
                                            attrs: { title: "添加用户" },
                                            model: {
                                              value:
                                                _vm.approverForm.circulateUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.approverForm,
                                                  "circulateUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "approverForm.circulateUser",
                                            },
                                          }),
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _vm._v(
                                                    "抄送人范围\n                    "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "抄送人员增加人员选择范围附加条件",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-select",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .extraCopyRule,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm,
                                                        "extraCopyRule",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.extraCopyRule",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.extraRuleOptions,
                                                  function (item, i) {
                                                    return _c("el-option", {
                                                      key: i,
                                                      attrs: {
                                                        label:
                                                          item.value == 1
                                                            ? "无抄送人范围"
                                                            : item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-checkbox",
                                            {
                                              model: {
                                                value:
                                                  _vm.approverForm.isCustomCopy,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.approverForm,
                                                    "isCustomCopy",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "approverForm.isCustomCopy",
                                              },
                                            },
                                            [_vm._v("允许自选抄送人")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "高级设置", name: "senior" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: { "label-position": "top" },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "操作设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("操作设置")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .hasSaveBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm,
                                                        "hasSaveBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.hasSaveBtn",
                                                  },
                                                },
                                                [_vm._v("暂存")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.approverForm
                                                      .saveBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.approverForm,
                                                      "saveBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "approverForm.saveBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .hasAuditBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm,
                                                        "hasAuditBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.hasAuditBtn",
                                                  },
                                                },
                                                [_vm._v("同意")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.approverForm
                                                      .auditBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.approverForm,
                                                      "auditBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "approverForm.auditBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .hasRejectBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm,
                                                        "hasRejectBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.hasRejectBtn",
                                                  },
                                                },
                                                [_vm._v("退回")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.approverForm
                                                      .rejectBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.approverForm,
                                                      "rejectBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "approverForm.rejectBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .hasRevokeBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm,
                                                        "hasRevokeBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.hasRevokeBtn",
                                                  },
                                                },
                                                [_vm._v("撤回")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.approverForm
                                                      .revokeBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.approverForm,
                                                      "revokeBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "approverForm.revokeBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .hasTransferBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm,
                                                        "hasTransferBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.hasTransferBtn",
                                                  },
                                                },
                                                [_vm._v("转审")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.approverForm
                                                      .transferBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.approverForm,
                                                      "transferBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "approverForm.transferBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .hasPrintBtn,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm,
                                                        "hasPrintBtn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.hasPrintBtn",
                                                  },
                                                },
                                                [_vm._v("打印")]
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.approverForm
                                                      .printBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.approverForm,
                                                      "printBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "approverForm.printBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.approverForm.hasPrintBtn
                                            ? _c(
                                                "div",
                                                { staticClass: "per-cell" },
                                                [
                                                  _c("p", {
                                                    staticStyle: {
                                                      width: "112px",
                                                    },
                                                  }),
                                                  _c("SNOWS-TreeSelect", {
                                                    attrs: {
                                                      options: _vm.printTplList,
                                                      placeholder:
                                                        "请选择打印模板",
                                                      lastLevel: "",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .printId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm,
                                                          "printId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.printId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "per-cell" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "has-free-approver",
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      model: {
                                                        value:
                                                          _vm.approverForm
                                                            .hasFreeApproverBtn,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.approverForm,
                                                            "hasFreeApproverBtn",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "approverForm.hasFreeApproverBtn",
                                                      },
                                                    },
                                                    [
                                                      _vm._v("加签"),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            content:
                                                              "允许在审批单中增加临时审批人",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("a", {
                                                            staticClass:
                                                              "el-icon-warning-outline",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("el-input", {
                                                model: {
                                                  value:
                                                    _vm.approverForm
                                                      .hasFreeApproverBtnText,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.approverForm,
                                                      "hasFreeApproverBtnText",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "approverForm.hasFreeApproverBtnText",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  staticStyle: { "margin-top": "-20px" },
                                  attrs: {
                                    "label-width": "90px",
                                    "label-position": "left",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "自动同意" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("自动同意\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "当前审批节点表单必填字段为空工单流转时不做校验，下一审批节点设置候选人员、选择分支、异常节点时当前审批节点规则失效",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.approverForm.hasAgreeRule,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm,
                                              "hasAgreeRule",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "approverForm.hasAgreeRule",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.approverForm.hasAgreeRule,
                                          expression:
                                            "approverForm.hasAgreeRule",
                                        },
                                      ],
                                      staticClass:
                                        "form-item-content form-item-content-first",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-sub-title" },
                                        [_vm._v("同意规则配置")]
                                      ),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { multiple: "" },
                                              model: {
                                                value:
                                                  _vm.approverForm.agreeRules,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.approverForm,
                                                    "agreeRules",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "approverForm.agreeRules",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "审批人为发起人",
                                                  value: 2,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label:
                                                    "审批人与上一审批节点处理人相同",
                                                  value: 3,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "审批人审批过",
                                                  value: 4,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("手写签名\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "审批人在进行审批操作时需手写签名",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.approverForm.hasSign,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm,
                                              "hasSign",
                                              $$v
                                            )
                                          },
                                          expression: "approverForm.hasSign",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("意见填写\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "审批人在进行审批操作需填写意见",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value: _vm.approverForm.hasOpinion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm,
                                              "hasOpinion",
                                              $$v
                                            )
                                          },
                                          expression: "approverForm.hasOpinion",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "说明" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("说明")]
                                      ),
                                      _c("el-input", {
                                        attrs: { type: "textarea", rows: 3 },
                                        model: {
                                          value: _vm.approverForm.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "approverForm.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "表单权限2", name: "formAuth" } },
                        [
                          _c(
                            "div",
                            { staticClass: "form-auth-table" },
                            [
                              _c(
                                "el-table",
                                {
                                  staticClass: "SNOWS-common-table",
                                  attrs: {
                                    data: _vm.getFormOperates(),
                                    size: "mini",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "name",
                                      label: "表单字段",
                                      align: "left",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "write",
                                      label: "操作",
                                      align: "center",
                                      width: "250",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value: scope.row.read,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "read",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.read",
                                                  },
                                                },
                                                [_vm._v("查看")]
                                              ),
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value: scope.row.write,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "write",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.write",
                                                  },
                                                },
                                                [_vm._v("编辑")]
                                              ),
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      scope.row
                                                        .requiredDisabled,
                                                  },
                                                  model: {
                                                    value: scope.row.required,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "required",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.required",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  必填"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      883505694
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "节点事件" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    model: _vm.approverForm,
                                    "label-position": "left",
                                  },
                                },
                                [
                                  _c("el-alert", {
                                    attrs: {
                                      title:
                                        "开启后可配置触发事件同时进行参数赋值",
                                      type: "warning",
                                      closable: false,
                                      "show-icon": "",
                                    },
                                  }),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mt-10",
                                      attrs: { label: "同意事件" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("同意事件")]
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.approverForm.approveFuncConfig
                                              .on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm
                                                .approveFuncConfig,
                                              "on",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "approverForm.approveFuncConfig.on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.approveFuncConfig.on
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("接口设置")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c("interface-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .approveFuncConfig
                                                      .interfaceId,
                                                  title:
                                                    _vm.approverForm
                                                      .approveFuncConfig
                                                      .interfaceName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onFuncChange(
                                                      "approverForm",
                                                      "approveFuncConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .approveFuncConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "200",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required-sign",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .required
                                                                    ? "*"
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .fieldName
                                                                  ? scope.row
                                                                      .field +
                                                                      "(" +
                                                                      scope.row
                                                                        .fieldName +
                                                                      ")"
                                                                  : scope.row
                                                                      .field
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  97883127
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择表单字段",
                                                                clearable: "",
                                                                filterable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRelationFieldChange(
                                                                      $event,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .relationField,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "relationField",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.relationField",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option-group",
                                                                _vm._l(
                                                                  _vm.systemFieldOptions,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.__vModel__,
                                                                        attrs: {
                                                                          label:
                                                                            item
                                                                              .__config__
                                                                              .label
                                                                              ? item.__vModel__ +
                                                                                "(" +
                                                                                item
                                                                                  .__config__
                                                                                  .label +
                                                                                ")"
                                                                              : item.__vModel__,
                                                                          value:
                                                                            item.__vModel__,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              scope.row.required
                                                                ? _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.funcRequiredOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.formFieldsOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  542453351
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "退回事件" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("退回事件")]
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.approverForm.rejectFuncConfig
                                              .on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm.rejectFuncConfig,
                                              "on",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "approverForm.rejectFuncConfig.on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.rejectFuncConfig.on
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("接口设置")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c("interface-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .rejectFuncConfig
                                                      .interfaceId,
                                                  title:
                                                    _vm.approverForm
                                                      .rejectFuncConfig
                                                      .interfaceName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onFuncChange(
                                                      "approverForm",
                                                      "rejectFuncConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .rejectFuncConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "200",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required-sign",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .required
                                                                    ? "*"
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .fieldName
                                                                  ? scope.row
                                                                      .field +
                                                                      "(" +
                                                                      scope.row
                                                                        .fieldName +
                                                                      ")"
                                                                  : scope.row
                                                                      .field
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  97883127
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择表单字段",
                                                                clearable: "",
                                                                filterable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRelationFieldChange(
                                                                      $event,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .relationField,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "relationField",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.relationField",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option-group",
                                                                _vm._l(
                                                                  _vm.systemFieldOptions,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.__vModel__,
                                                                        attrs: {
                                                                          label:
                                                                            item
                                                                              .__config__
                                                                              .label
                                                                              ? item.__vModel__ +
                                                                                "(" +
                                                                                item
                                                                                  .__config__
                                                                                  .label +
                                                                                ")"
                                                                              : item.__vModel__,
                                                                          value:
                                                                            item.__vModel__,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              scope.row.required
                                                                ? _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.funcRequiredOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.formFieldsOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  542453351
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "撤回事件" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("撤回事件")]
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.approverForm.recallFuncConfig
                                              .on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm.recallFuncConfig,
                                              "on",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "approverForm.recallFuncConfig.on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.recallFuncConfig.on
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("接口设置")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c("interface-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .recallFuncConfig
                                                      .interfaceId,
                                                  title:
                                                    _vm.approverForm
                                                      .recallFuncConfig
                                                      .interfaceName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onFuncChange(
                                                      "approverForm",
                                                      "recallFuncConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .recallFuncConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "200",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required-sign",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .required
                                                                    ? "*"
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .fieldName
                                                                  ? scope.row
                                                                      .field +
                                                                      "(" +
                                                                      scope.row
                                                                        .fieldName +
                                                                      ")"
                                                                  : scope.row
                                                                      .field
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  97883127
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择表单字段",
                                                                clearable: "",
                                                                filterable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRelationFieldChange(
                                                                      $event,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .relationField,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "relationField",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.relationField",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option-group",
                                                                _vm._l(
                                                                  _vm.systemFieldOptions,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.__vModel__,
                                                                        attrs: {
                                                                          label:
                                                                            item
                                                                              .__config__
                                                                              .label
                                                                              ? item.__vModel__ +
                                                                                "(" +
                                                                                item
                                                                                  .__config__
                                                                                  .label +
                                                                                ")"
                                                                              : item.__vModel__,
                                                                          value:
                                                                            item.__vModel__,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              scope.row.required
                                                                ? _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.funcRequiredOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.formFieldsOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  542453351
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mt-10",
                                      attrs: { label: "超时事件" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("超时事件")]
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.approverForm.overTimeFuncConfig
                                              .on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm
                                                .overTimeFuncConfig,
                                              "on",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "approverForm.overTimeFuncConfig.on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.overTimeFuncConfig.on
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("接口设置")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c("interface-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .overTimeFuncConfig
                                                      .interfaceId,
                                                  title:
                                                    _vm.approverForm
                                                      .overTimeFuncConfig
                                                      .interfaceName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onFuncChange(
                                                      "approverForm",
                                                      "overTimeFuncConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .overTimeFuncConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "200",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required-sign",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .required
                                                                    ? "*"
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .fieldName
                                                                  ? scope.row
                                                                      .field +
                                                                      "(" +
                                                                      scope.row
                                                                        .fieldName +
                                                                      ")"
                                                                  : scope.row
                                                                      .field
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  97883127
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择表单字段",
                                                                clearable: "",
                                                                filterable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRelationFieldChange(
                                                                      $event,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .relationField,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "relationField",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.relationField",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option-group",
                                                                _vm._l(
                                                                  _vm.systemFieldOptions,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.__vModel__,
                                                                        attrs: {
                                                                          label:
                                                                            item
                                                                              .__config__
                                                                              .label
                                                                              ? item.__vModel__ +
                                                                                "(" +
                                                                                item
                                                                                  .__config__
                                                                                  .label +
                                                                                ")"
                                                                              : item.__vModel__,
                                                                          value:
                                                                            item.__vModel__,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              scope.row.required
                                                                ? _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.funcRequiredOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.formFieldsOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  542453351
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mt-10",
                                      attrs: { label: "提醒事件" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("提醒事件")]
                                      ),
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.approverForm.noticeFuncConfig
                                              .on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.approverForm.noticeFuncConfig,
                                              "on",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "approverForm.noticeFuncConfig.on",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.noticeFuncConfig.on
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-item-content form-item-content-first",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("接口设置")]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0" } },
                                            [
                                              _c("interface-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .noticeFuncConfig
                                                      .interfaceId,
                                                  title:
                                                    _vm.approverForm
                                                      .noticeFuncConfig
                                                      .interfaceName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onFuncChange(
                                                      "approverForm",
                                                      "noticeFuncConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .noticeFuncConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "200",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "required-sign",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .required
                                                                    ? "*"
                                                                    : ""
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                scope.row
                                                                  .fieldName
                                                                  ? scope.row
                                                                      .field +
                                                                      "(" +
                                                                      scope.row
                                                                        .fieldName +
                                                                      ")"
                                                                  : scope.row
                                                                      .field
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  97883127
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择表单字段",
                                                                clearable: "",
                                                                filterable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRelationFieldChange(
                                                                      $event,
                                                                      scope.row
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .relationField,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "relationField",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.relationField",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-option-group",
                                                                _vm._l(
                                                                  _vm.systemFieldOptions,
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "el-option",
                                                                      {
                                                                        key: item.__vModel__,
                                                                        attrs: {
                                                                          label:
                                                                            item
                                                                              .__config__
                                                                              .label
                                                                              ? item.__vModel__ +
                                                                                "(" +
                                                                                item
                                                                                  .__config__
                                                                                  .label +
                                                                                ")"
                                                                              : item.__vModel__,
                                                                          value:
                                                                            item.__vModel__,
                                                                        },
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              scope.row.required
                                                                ? _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.funcRequiredOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _c(
                                                                    "el-option-group",
                                                                    _vm._l(
                                                                      _vm.formFieldsOptions,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: item.__vModel__,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item
                                                                                    .__config__
                                                                                    .label
                                                                                    ? item.__vModel__ +
                                                                                      "(" +
                                                                                      item
                                                                                        .__config__
                                                                                        .label +
                                                                                      ")"
                                                                                    : item.__vModel__,
                                                                                value:
                                                                                  item.__vModel__,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  542453351
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "节点通知" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    model: _vm.approverForm,
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c("el-alert", {
                                    attrs: {
                                      title:
                                        "该通知设置在【消息中心】-【消息发送配置】维护，选择默认则站内信提醒，选择关闭则不提醒。",
                                      type: "warning",
                                      closable: false,
                                      "show-icon": "",
                                    },
                                  }),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mt-10" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点同意\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "当前节点审核人同意的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.approverForm.approveMsgConfig
                                                .on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm
                                                  .approveMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.approveMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nodeNoticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.approveMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .approveMsgConfig.msgId,
                                                  title:
                                                    _vm.approverForm
                                                      .approveMsgConfig.msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "approverForm",
                                                      "approveMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .approveMsgConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.systemFieldOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.formFieldsOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  848379261
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点退回\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "当前节点审核人退回的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.approverForm.rejectMsgConfig
                                                .on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm
                                                  .rejectMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.rejectMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nodeNoticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.rejectMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .rejectMsgConfig.msgId,
                                                  title:
                                                    _vm.approverForm
                                                      .rejectMsgConfig.msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "approverForm",
                                                      "rejectMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .rejectMsgConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.systemFieldOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.formFieldsOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  848379261
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点抄送\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "当前节点抄送的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.approverForm.copyMsgConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm.copyMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.copyMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nodeNoticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.copyMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .copyMsgConfig.msgId,
                                                  title:
                                                    _vm.approverForm
                                                      .copyMsgConfig.msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "approverForm",
                                                      "copyMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .copyMsgConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.systemFieldOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _c(
                                                                  "el-option-group",
                                                                  _vm._l(
                                                                    _vm.formFieldsOptions,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: item.__vModel__,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item
                                                                                  .__config__
                                                                                  .label
                                                                                  ? item.__vModel__ +
                                                                                    "(" +
                                                                                    item
                                                                                      .__config__
                                                                                      .label +
                                                                                    ")"
                                                                                  : item.__vModel__,
                                                                              value:
                                                                                item.__vModel__,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  848379261
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点超时\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "当前节点超时的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.approverForm.overTimeMsgConfig
                                                .on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm
                                                  .overTimeMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.overTimeMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nodeNoticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.overTimeMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .overTimeMsgConfig.msgId,
                                                  title:
                                                    _vm.approverForm
                                                      .overTimeMsgConfig
                                                      .msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "approverForm",
                                                      "overTimeMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .overTimeMsgConfig
                                                  .templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.funcOptions,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.__vModel__,
                                                                      attrs: {
                                                                        label:
                                                                          item
                                                                            .__config__
                                                                            .label
                                                                            ? item.__vModel__ +
                                                                              "(" +
                                                                              item
                                                                                .__config__
                                                                                .label +
                                                                              ")"
                                                                            : item.__vModel__,
                                                                        value:
                                                                          item.__vModel__,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  865070175
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("节点提醒\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "当前节点提醒的时候",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.approverForm.noticeMsgConfig
                                                .on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm
                                                  .noticeMsgConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.noticeMsgConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nodeNoticeOptionsData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.noticeMsgConfig.on === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "form-item-content",
                                          staticStyle: {
                                            "margin-bottom": "18px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "发送配置" } },
                                            [
                                              _c("msg-dialog", {
                                                attrs: {
                                                  value:
                                                    _vm.approverForm
                                                      .noticeMsgConfig.msgId,
                                                  title:
                                                    _vm.approverForm
                                                      .noticeMsgConfig.msgName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onMsgChange(
                                                      "approverForm",
                                                      "noticeMsgConfig",
                                                      arguments
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-sub-title" },
                                            [_vm._v("参数设置")]
                                          ),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.approverForm
                                                  .noticeMsgConfig.templateJson,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "index",
                                                  width: "50",
                                                  label: "序号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "模板名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goDetail(
                                                                      scope.row
                                                                        .templateId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  scope.row
                                                                    .msgTemplateName
                                                                ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3316289560
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "field",
                                                  label: "参数名称",
                                                  width: "150",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "parameter-box",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item.field
                                                                    ) +
                                                                    "(" +
                                                                    _vm._s(
                                                                      item.fieldName
                                                                    ) +
                                                                    ")\n                    "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4038422356
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "value",
                                                  label: "表单字段",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return _vm._l(
                                                          scope.row.paramJson,
                                                          function (it, index) {
                                                            return _c(
                                                              "el-select",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "variable-box",
                                                                attrs: {
                                                                  placeholder:
                                                                    "请选择表单字段",
                                                                  clearable: "",
                                                                  filterable:
                                                                    "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onRelationFieldChange(
                                                                        $event,
                                                                        it
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    it.relationField,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        it,
                                                                        "relationField",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "it.relationField",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.funcOptions,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item.__vModel__,
                                                                      attrs: {
                                                                        label:
                                                                          item
                                                                            .__config__
                                                                            .label
                                                                            ? item.__vModel__ +
                                                                              "(" +
                                                                              item
                                                                                .__config__
                                                                                .label +
                                                                              ")"
                                                                            : item.__vModel__,
                                                                        value:
                                                                          item.__vModel__,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  865070175
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "超时处理" } },
                        [
                          _c(
                            "el-scrollbar",
                            { staticClass: "config-scrollbar" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "pd-10-20",
                                  attrs: {
                                    model: _vm.approverForm,
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "限时设置" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [_vm._v("限时设置")]
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.approverForm.timeLimitConfig
                                                .on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm
                                                  .timeLimitConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.timeLimitConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nodeNoticeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.timeLimitConfig.on === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "节点限定时长起始值",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .timeLimitConfig
                                                        .nodeLimit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm
                                                          .timeLimitConfig,
                                                        "nodeLimit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.timeLimitConfig.nodeLimit",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.overTimeOptions,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.approverForm.timeLimitConfig
                                            .nodeLimit === 2
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "表单字段" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择字段",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.approverForm
                                                            .timeLimitConfig
                                                            .formField,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.approverForm
                                                              .timeLimitConfig,
                                                            "formField",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "approverForm.timeLimitConfig.formField",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.usedFormItems,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.__vModel__,
                                                          attrs: {
                                                            label:
                                                              item.__config__
                                                                .label,
                                                            value:
                                                              item.__vModel__,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("节点处理限定时长(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 1, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .timeLimitConfig
                                                          .duringDeal,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm
                                                            .timeLimitConfig,
                                                          "duringDeal",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.timeLimitConfig.duringDeal",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mt-10" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("超时设置\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "超过设置的节点处理限定时间即为超时",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.approverForm.overTimeConfig
                                                .on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm.overTimeConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.overTimeConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nodeNoticeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.overTimeConfig.on === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("第一次超时时间(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 0, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .overTimeConfig
                                                          .firstOver,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm
                                                            .overTimeConfig,
                                                          "firstOver",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.overTimeConfig.firstOver",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            {
                                              staticClass: "mt-10",
                                              attrs: { gutter: 20 },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("超时间隔(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 0, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .overTimeConfig
                                                          .overTimeDuring,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm
                                                            .overTimeConfig,
                                                          "overTimeDuring",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.overTimeConfig.overTimeDuring",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("el-row", [_vm._v("超时事务")]),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .overTimeConfig
                                                        .overNotice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm
                                                          .overTimeConfig,
                                                        "overNotice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.overTimeConfig.overNotice",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "超时通知\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "勾选后才能进行超时消息发送（站内信系统默认发送，第三方超时消息需在节点通知内配置）",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .overTimeConfig
                                                        .overAutoApprove,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm
                                                          .overTimeConfig,
                                                        "overAutoApprove",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.overTimeConfig.overAutoApprove",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "超时自动审批\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "当前审批节点表单必填字段为空工单流转时不做校验，下一审批节点设置候选人员、选择分支、异常节点时当前审批节点规则失效",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("超时次数(次)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 1, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .overTimeConfig
                                                          .overAutoApproveTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm
                                                            .overTimeConfig,
                                                          "overAutoApproveTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.overTimeConfig.overAutoApproveTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .overTimeConfig
                                                        .overEvent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm
                                                          .overTimeConfig,
                                                        "overEvent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.overTimeConfig.overEvent",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "超时事件\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "请在节点事件内配置超时事件",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("超时次数(次)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 1, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .overTimeConfig
                                                          .overEventTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm
                                                            .overTimeConfig,
                                                          "overEventTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.overTimeConfig.overEventTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { staticClass: "mt-10" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-item-label",
                                          attrs: { slot: "label" },
                                          slot: "label",
                                        },
                                        [
                                          _vm._v("提醒设置\n                "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "还未到达设置的节点处理限定时间即为提醒",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("a", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-content",
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.approverForm.noticeConfig.on,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.approverForm.noticeConfig,
                                                "on",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "approverForm.noticeConfig.on",
                                          },
                                        },
                                        _vm._l(
                                          _vm.nodeNoticeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.approverForm.noticeConfig.on === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "form-item-content" },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("第一次提醒时间(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 1, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .noticeConfig
                                                          .firstOver,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm
                                                            .noticeConfig,
                                                          "firstOver",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.noticeConfig.firstOver",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            {
                                              staticClass: "mt-10",
                                              attrs: { gutter: 20 },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [_vm._v("提醒间隔(时)")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: { min: 0, step: 1 },
                                                    model: {
                                                      value:
                                                        _vm.approverForm
                                                          .noticeConfig
                                                          .overTimeDuring,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.approverForm
                                                            .noticeConfig,
                                                          "overTimeDuring",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "approverForm.noticeConfig.overTimeDuring",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("el-row", [_vm._v("提醒事务")]),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .noticeConfig
                                                        .overNotice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm
                                                          .noticeConfig,
                                                        "overNotice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.noticeConfig.overNotice",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "提醒通知\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "勾选后才能进行提醒消息发送（站内信系统默认发送，第三方超时消息需在节点通知内配置）",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  model: {
                                                    value:
                                                      _vm.approverForm
                                                        .noticeConfig.overEvent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.approverForm
                                                          .noticeConfig,
                                                        "overEvent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "approverForm.noticeConfig.overEvent",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "提醒事件\n                  "
                                                  ),
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "请在节点事件内配置提醒事件",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c("a", {
                                                        staticClass:
                                                          "el-icon-warning-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-row",
                                                { attrs: { gutter: 20 } },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [_vm._v("提醒次数(次)")]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 16 } },
                                                    [
                                                      _c("el-input-number", {
                                                        attrs: {
                                                          min: 1,
                                                          step: 1,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.approverForm
                                                              .noticeConfig
                                                              .overEventTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.approverForm
                                                                .noticeConfig,
                                                              "overEventTime",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "approverForm.noticeConfig.overEventTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.confirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "SNOWS-dialog SNOWS-dialog_center rule-dialog",
              attrs: {
                title: "数据传递",
                "close-on-click-modal": false,
                visible: _vm.ruleVisible,
                "lock-scroll": "",
                "append-to-body": "",
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.ruleVisible = $event
                },
              },
            },
            [
              _c(
                "el-tabs",
                { staticClass: "SNOWS-el_tabs node-tabs" },
                _vm._l(_vm.assignList, function (item, i) {
                  return _c(
                    "el-tab-pane",
                    { key: i, attrs: { label: item.title } },
                    [
                      _c("div", { staticClass: "option-box-tip" }, [
                        _vm._v(
                          "当父流程流转到子流程时，将对应的上一节点表单字段赋值给子流程发起节点"
                        ),
                      ]),
                      _vm._l(item.ruleList, function (child, cIndex) {
                        return _c(
                          "el-row",
                          {
                            key: cIndex,
                            staticClass: "mb-10",
                            attrs: { gutter: 10 },
                          },
                          [
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 2 } },
                              [_vm._v("父流程")]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择字段",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: child.parentField,
                                      callback: function ($$v) {
                                        _vm.$set(child, "parentField", $$v)
                                      },
                                      expression: "child.parentField",
                                    },
                                  },
                                  _vm._l(item.formFieldList, function (field) {
                                    return _c("el-option", {
                                      key: field.__vModel__,
                                      attrs: {
                                        label: field.__config__.label,
                                        value: field.__vModel__,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "rule-cell mid",
                                attrs: { span: 4 },
                              },
                              [_vm._v("赋值给")]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 2 } },
                              [_vm._v("子流程")]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择字段",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: child.childField,
                                      callback: function ($$v) {
                                        _vm.$set(child, "childField", $$v)
                                      },
                                      expression: "child.childField",
                                    },
                                  },
                                  _vm._l(
                                    _vm.childFieldOptions,
                                    function (field) {
                                      return _c("el-option", {
                                        key: field.__vModel__,
                                        attrs: {
                                          label: field.__config__.label,
                                          value: field.__vModel__,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 2 } },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delTransmitRule(i, cIndex)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "table-actions",
                          on: {
                            click: function ($event) {
                              return _vm.addTransmitRule(i)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", icon: "el-icon-plus" } },
                            [_vm._v("新增规则")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                }),
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.ruleVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.saveRule } },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticClass: "SNOWS-dialog SNOWS-dialog_center rule-dialog",
              attrs: {
                title: "数据传递",
                "close-on-click-modal": false,
                visible: _vm.approverTransmitRuleVisible,
                "lock-scroll": "",
                "append-to-body": "",
                width: "700px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.approverTransmitRuleVisible = $event
                },
              },
            },
            [
              _c(
                "el-tabs",
                { staticClass: "SNOWS-el_tabs node-tabs" },
                _vm._l(_vm.assignList, function (item, i) {
                  return _c(
                    "el-tab-pane",
                    { key: i, attrs: { label: item.title } },
                    [
                      _c("div", { staticClass: "option-box-tip" }, [
                        _vm._v(
                          "当节点流转到本节点时，将对应的上一节点的字段赋值给本节点"
                        ),
                      ]),
                      _vm._l(item.ruleList, function (child, cIndex) {
                        return _c(
                          "el-row",
                          {
                            key: cIndex,
                            staticClass: "mb-10",
                            attrs: { gutter: 10 },
                          },
                          [
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 2 } },
                              [_vm._v("上节点")]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择字段",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: child.parentField,
                                      callback: function ($$v) {
                                        _vm.$set(child, "parentField", $$v)
                                      },
                                      expression: "child.parentField",
                                    },
                                  },
                                  _vm._l(item.formFieldList, function (field) {
                                    return _c("el-option", {
                                      key: field.__vModel__,
                                      attrs: {
                                        label: field.__config__.label,
                                        value: field.__vModel__,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "rule-cell mid",
                                attrs: { span: 4 },
                              },
                              [_vm._v("赋值给")]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 2 } },
                              [_vm._v("本节点")]
                            ),
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 7 } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择字段",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: child.childField,
                                      callback: function ($$v) {
                                        _vm.$set(child, "childField", $$v)
                                      },
                                      expression: "child.childField",
                                    },
                                  },
                                  _vm._l(
                                    _vm.formFieldsOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.__vModel__,
                                        attrs: {
                                          label: item.__config__.label,
                                          value: item.__vModel__,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { staticClass: "rule-cell", attrs: { span: 2 } },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delTransmitRule(i, cIndex)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "table-actions",
                          on: {
                            click: function ($event) {
                              return _vm.addTransmitRule(i)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", icon: "el-icon-plus" } },
                            [_vm._v("新增规则")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                }),
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.approverTransmitRuleVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveApproverTransmitRule },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("FormulaDialog", {
            attrs: {
              visible: _vm.formulaVisible,
              value: _vm.activeItem.field,
              formFieldsOptions: _vm.formFieldsOptions,
            },
            on: {
              "update:visible": function ($event) {
                _vm.formulaVisible = $event
              },
              updateFormula: _vm.updateFormula,
            },
          }),
          _vm.viewVisible
            ? _c("Detail", {
                ref: "View",
                on: {
                  close: function ($event) {
                    _vm.viewVisible = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }