var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SNOWS-common-layout" },
    [
      _c("CommonTree", {
        attrs: {
          treeData: _vm.treeData,
          defaultProps: _vm.defaultProps,
          searchTitle: _vm.searchTitle,
          treeTitle: "流程分类",
          isShowdig: true,
          showCheckbox: false,
        },
        on: {
          getTreeAdd: function ($event) {
            _vm.flowCate = true
          },
          getTreeEdit: _vm.getTreeEdit,
          getTreeDelete: _vm.getTreeDelete,
          getNodeClick: _vm.getNodeClick,
        },
      }),
      _c(
        "div",
        { staticClass: "SNOWS-common-layout-center" },
        [
          _c("head-layout", {
            attrs: {
              "head-title": "流程设计",
              "head-btn-options": _vm.headBtnOptions,
            },
            on: {
              "head-add": function ($event) {
                return _vm.addFlow(1)
              },
            },
          }),
          _c(
            "el-row",
            {
              staticClass: "SNOWS-common-search-box",
              staticStyle: { "margin-bottom": "0" },
              attrs: { gutter: 16 },
            },
            [
              _c(
                "el-form",
                {
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入流程名称查询",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search()
                              },
                            },
                            model: {
                              value: _vm.fullName,
                              callback: function ($$v) {
                                _vm.fullName = $$v
                              },
                              expression: "fullName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入流程编码查询",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.search()
                              },
                            },
                            model: {
                              value: _vm.enCode,
                              callback: function ($$v) {
                                _vm.enCode = $$v
                              },
                              expression: "enCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-button-group",
                        [
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.search()
                              },
                            },
                          }),
                          _c("el-button", {
                            attrs: {
                              size: "mini",
                              icon: "reset-refresh icon-refresh",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.reset()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "SNOWS-common-layout-main SNOWS-flex-main",
              staticStyle: { padding: "0 12px 12px 12px" },
            },
            [
              _c(
                "SNOWSTable",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: { data: _vm.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "fullName",
                      label: "流程名称",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "enCode",
                      label: "流程编码",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "category",
                      label: "流程分类",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "type", label: "流程类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.type == 0 ? "发起流程" : "功能流程"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "visibleType",
                      label: "可见范围",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.visibleType == 0
                                    ? "全部可见"
                                    : "部分可见"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      prop: "sortCode",
                      label: "排序",
                      width: "70",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "creatorUser",
                      label: "创建人",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "left",
                      prop: "version",
                      label: "版本号",
                      width: "70",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-tag", [
                              _vm._v("V:" + _vm._s(scope.row.version)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "enabledMark",
                      label: "状态",
                      width: "70",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    scope.row.enabledMark == 1
                                      ? "success"
                                      : "danger",
                                  "disable-transitions": "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.enabledMark == 1
                                        ? "启用"
                                        : "禁用"
                                    )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "tableOpts",
                              {
                                on: {
                                  edit: function ($event) {
                                    return _vm.addOrUpdateHandle(
                                      scope.row.id,
                                      scope.row.type
                                    )
                                  },
                                  del: function ($event) {
                                    return _vm.handleDel(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-dropdown",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini",
                                            },
                                          },
                                          [
                                            _vm._v(_vm._s("更多")),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-down el-icon--right",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        scope.row.enabledMark != 1
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handleUpdate(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    启用流程"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.enabledMark == 1
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handleUpdate(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    禁用流程"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.showManage(
                                                  scope.row.id,
                                                  scope.row.fullName
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    版本管理"
                                            ),
                                          ]
                                        ),
                                        scope.row.hasAssistBtn == 1
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.management(
                                                      scope.row.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    协管流程"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.currentPage,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "currentPage", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: _vm.initData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.formVisible
        ? _c("Form", { ref: "Form", on: { close: _vm.closeForm } })
        : _vm._e(),
      _vm.manageVisible
        ? _c("FlowManage", {
            ref: "FlowManage",
            on: { close: _vm.closeManage },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center",
          attrs: {
            title: "协管流程",
            "close-on-click-modal": false,
            visible: _vm.managementVisible,
            "lock-scroll": "",
            "append-to-body": "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.managementVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "dataForm", attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "设置协管员" } },
                [
                  _c("user-select", {
                    attrs: {
                      multiple: "",
                      placeholder: "请选择该流程协管人员",
                    },
                    model: {
                      value: _vm.managementUserId,
                      callback: function ($$v) {
                        _vm.managementUserId = $$v
                      },
                      expression: "managementUserId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.managementVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.handleApproval()
                    },
                  },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "流程分类",
            visible: _vm.flowCate,
            "close-on-click-modal": false,
            "lock-scroll": "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.flowCate = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类编码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上级分类" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动区域" },
                      model: {
                        value: _vm.form.pid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pid", $$v)
                        },
                        expression: "form.pid",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        [
                          _c("el-tree", {
                            ref: "tree",
                            attrs: {
                              data: _vm.treeData,
                              "default-expand-all": "",
                              "node-key": "id",
                              "default-check": "",
                              props: _vm.defaultProps,
                            },
                            on: { "node-click": _vm.handleNodeClick },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.flowCate = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.getTreeAdd()
                    },
                  },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }