var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          staticClass: "SNOWS-dialog SNOWS-dialog_center formula-dialog",
          attrs: {
            title: "公式编辑",
            "close-on-click-modal": false,
            "lock-scroll": "",
            "append-to-body": "",
            width: "800px",
            "modal-append-to-body": false,
          },
          on: { open: _vm.onOpen },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "formula-dialog-body" }, [
        _c(
          "div",
          { staticClass: "code-editor-area" },
          [
            _c("JSONEditor", {
              ref: "codemirror",
              staticClass: "json-editor",
              attrs: { mode: "javascript" },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "operation-area" }, [
          _c("div", { staticClass: "area-item field-area" }, [
            _c("div", { staticClass: "area-title" }, [_vm._v("当前表单字段")]),
            _c(
              "div",
              { staticClass: "area-content" },
              [
                _c("el-tree", {
                  attrs: {
                    data: _vm.fieldsOptions,
                    "node-key": "id",
                    "default-expand-all": "",
                    "expand-on-click-node": false,
                    props: _vm.treeProps,
                  },
                  on: { "node-click": _vm.handleNodeClick },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "area-item formula-area" }, [
            _c("div", { staticClass: "area-title" }, [_vm._v("函数")]),
            _c(
              "div",
              { staticClass: "area-content" },
              [
                _c("el-tree", {
                  attrs: {
                    data: _vm.formulaData,
                    "node-key": "id",
                    "default-expand-all": "",
                    "expand-on-click-node": false,
                    props: _vm.treeProps,
                  },
                  on: { "node-click": _vm.formulaNodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ node, data }) {
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c(
                            "span",
                            {
                              staticClass: "text",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.formulaMouseenter(data)
                                },
                              },
                            },
                            [_vm._v(_vm._s(node.label))]
                          ),
                        ])
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "area-item formula-desc-area" }, [
            _c("div", { staticClass: "area-title" }, [
              _vm._v(_vm._s(_vm.descTitle)),
            ]),
            _c("div", {
              staticClass: "area-content",
              domProps: { innerHTML: _vm._s(_vm.descContent) },
            }),
          ]),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }